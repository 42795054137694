import { Component, OnInit } from '@angular/core';
import { WorkDetailsPageComponent } from 'src/app/components/work-details-page/work-details-page.component';
import { WorksService } from 'src/app/works.service';

@Component({
  selector: 'works-in-your-face',
  templateUrl: './works-in-your-face.component.html',
  styleUrls: ['./works-in-your-face.component.scss']
})
export class WorksInYourFaceComponent extends WorkDetailsPageComponent {

  constructor(worksSevice: WorksService) {
    super(worksSevice);
    super.workName = 'in-your-face-design-for-public-transport';
  }
}
