<div class="viewport section-margin">
  <div class="cover-photo" style="background-image: url('assets/img/fullres/croquis/mette_boldt_croquis_11.jpg')">
  </div>
  <div class="max-width-container vertical-center">
    <info-box title="Croquis">
      <info-box-item label="Category">
        <div class="label category drawing">Drawing</div>
      </info-box-item>
      <info-box-item label="Years">
        2014-2020
      </info-box-item>
      <info-box-item label="Skills">
        Sketching
      </info-box-item>
    </info-box>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>Introduction</h2>
    <p>Drawing has always been my hobby, and croquis is a way of drawing I enjoy in particular. The fast pace is fun and challenging, and it teaches me how to observe and put my ideas onto paper quickly. These skills I find extremely useful for my job as an experience designer.</p>

    <p>The sketches on this page were drawn in 1-10 minutes per pose.</p>
  </div>
</div>

<div class="max-width-container-img standard-margin img-row-two">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_09.jpg">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_13.jpg">
</div>

<div class="max-width-container-img standard-margin img-row-three">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_15.jpg">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_16.jpg">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_20.jpg">
</div>

<div class="max-width-container-img standard-margin img-row-two">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_10.jpg">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_14.jpg">
</div>

<div class="max-width-container-img standard-margin img-row-two">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_18.jpg">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_19.jpg">
</div>

<div class="max-width-container-img standard-margin img-row-two">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_22.jpg">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_23.jpg">
</div>

<div class="max-width-container-img standard-margin img-row-two">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_01.jpg">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_21.jpg">
</div>

<div class="max-width-container-img standard-margin img-row-two">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_02.jpg">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_06.jpg">
</div>

<div class="max-width-container-img standard-margin img-row-two">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_03.jpg">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_04.jpg">
</div>

<div class="max-width-container-img section-margin img-row-two">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_05.jpg">
  <img src="assets/img/fullres/croquis/mette_boldt_croquis_07.jpg">
</div>

<related-works>
  <card existingWork="selected-drawings"></card>
  <card existingWork="thurid"></card>
</related-works>
