<div class="viewport section-margin">
  <div class="cover-photo" style="background-image: url('assets/img/fullres/selected-drawings/mette_boldt_old_man.jpg')">
  </div>
  <div class="max-width-container vertical-center">
    <info-box title="Drawings and Illu&shy;stra&shy;tions">
      <info-box-item label="Category">
        <div class="label category drawing">Drawing</div>
      </info-box-item>
      <info-box-item label="Years">
        2014-2020
      </info-box-item>
      <info-box-item label="Skills">
        Drawing <span class="item-divider">//</span> Photoshop <span class="item-divider">//</span> Collage <span class="item-divider">//</span> Watercolors <span class="item-divider">//</span> Markers
      </info-box-item>
    </info-box>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>Introduction</h2>
    <p>I love expressing myself visually. Naturally, drawing is my hobby.</p>
  </div>
</div>

<div class="max-width-container-img section-margin img-row-two">
  <img loading="eager" src="assets/img/fullres/selected-drawings/mette_boldt_tnimd_death.jpg">
  <img loading="eager" src="assets/img/fullres/selected-drawings/mette_boldt_tnimd_death_scene.jpg">
</div>

<div class="max-width-container-img standard-margin img-row-three">
  <img loading="eager" src="assets/img/fullres/selected-drawings/mette_boldt_freddy.jpg">
  <img loading="eager" src="assets/img/fullres/selected-drawings/mette_boldt_line_portraits.jpg">
  <img loading="eager" src="assets/img/fullres/selected-drawings/mette_boldt_martin_gore_realism.jpg">
</div>

<div class="max-width-container-img section-margin img-row-three">
  <img loading="eager" src="assets/img/fullres/selected-drawings/watercolour_portrait_2.jpg">
  <img loading="eager" src="assets/img/fullres/selected-drawings/watercolour_portrait_1.jpg">
  <img loading="eager" src="assets/img/fullres/selected-drawings/mette_boldt_red_tree.jpg">
</div>

<div class="max-width-container section-margin">
  <div class="text-container center img-row-one">
    <img loading="eager" src="assets/img/fullres/selected-drawings/mette_boldt_woman.jpg">
  </div>
</div>

<div class="max-width-container-img section-margin img-row-two">
  <img loading="eager" src="assets/img/fullres/selected-drawings/mette_boldt_man_portrait.jpg">
  <img loading="eager" src="assets/img/fullres/selected-drawings/mette_boldt_bird.jpg">
</div>

<div class="max-width-container-img standard-margin img-row-two">
  <img loading="eager" src="assets/img/fullres/selected-drawings/perspective/mette_boldt_impro_perspective.jpg">
  <img loading="eager" src="assets/img/fullres/selected-drawings/perspective/mette_boldt_object_perspective.jpg">
</div>

<div class="max-width-container section-margin">
  <div class="text-container center img-row-one">
    <img loading="eager" src="assets/img/fullres/selected-drawings/perspective/mette_boldt_perspective_city.jpg">
  </div>
</div>

<div class="max-width-container-img section-margin img-row-two">
  <img loading="eager" src="assets/img/fullres/selected-drawings/still-life/mette_boldt_still_life_kettle.jpg">
  <img loading="eager" src="assets/img/fullres/selected-drawings/still-life/mette_boldt_still_life_glass.jpg">
</div>

<div class="max-width-container section-margin img-row-one">
  <img loading="eager" src="assets/img/fullres/selected-drawings/mette_boldt_collage_cdcovers.jpg">

  <div class="text-container center">
    <quote
      quote="Yes, I kind of like Depeche Mode. A lot."
      person="Mette">
    </quote>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center img-row-one">
    <img loading="eager" src="assets/img/fullres/selected-drawings/mette_boldt_jasper.jpg">
  </div>
</div>

<related-works>
  <card existingWork="croquis"></card>
  <card existingWork="thurid"></card>
</related-works>
