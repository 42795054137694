import { Component } from '@angular/core';
import { WorkDetailsPageComponent } from '../../components/work-details-page/work-details-page.component';
import { WorksService } from '../../works.service';

@Component({
  selector: 'app-works-unwrap',
  templateUrl: './works-unwrap.component.html',
  styleUrls: ['./works-unwrap.component.scss'],
})
export class WorksUnwrapComponent extends WorkDetailsPageComponent {
  constructor(worksSevice: WorksService) {
    super(worksSevice);
    super.workName = 'unwrap';
  }
}
