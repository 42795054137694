<div class="viewport section-margin">
  <div
    class="cover-photo"
    style="
      background-image: url('assets/img/fullres/kreativwerkbank/mette_boldt_kreativwerkbank_mockup.jpg');
    "
  ></div>
  <div class="max-width-container vertical-center">
    <info-box title="Kreativ&shy;Werkbank">
      <info-box-item label="Category">
        <div class="label category web-design">Web Design</div>
      </info-box-item>
      <info-box-item label="Year"> 2017 </info-box-item>
      <info-box-item label="Job"> webbrand gmbh </info-box-item>
      <info-box-item label="Skills">
        Web design <span class="item-divider">//</span> Html <span class="item-divider">//</span> CSS <span class="item-divider">//</span> Javascript
      </info-box-item>
    </info-box>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center section-margin">
    <h2>Introduction</h2>
    <p class="standard-margin">
      While working for
      <a href="https://webbrand.de/" target="_blank">webbrand GmbH</a>, I
      created this website for a small, local advertising agency. My task also
      included corporate design for print.
    </p>
    <a href="http://www.kreativwerkbank.de/" target="_blank"
      ><button>To the website</button></a
    >
  </div>

  <div class="img-row-one standard-margin">
    <img
      loading="eager"
      src="assets/img/fullres/kreativwerkbank/mette_boldt_kreativwerkbank_1.png"
    />
  </div>
  <div class="img-row-one standard-margin">
    <img
      loading="eager"
      src="assets/img/fullres/kreativwerkbank/mette_boldt_kreativwerkbank_2.png"
    />
  </div>
  <div class="img-row-one standard-margin">
    <img
      loading="eager"
      src="assets/img/fullres/kreativwerkbank/mette_boldt_kreativwerkbank_3.png"
    />
  </div>
  <div class="img-row-one standard-margin">
    <img
      loading="eager"
      src="assets/img/fullres/kreativwerkbank/mette_boldt_kreativwerkbank_4.png"
    />
  </div>
  <div class="img-row-one section-margin">
    <img
      loading="eager"
      src="assets/img/fullres/kreativwerkbank/mette_boldt_kreativwerkbank_5.png"
    />
  </div>
  <div class="img-row-one section-margin">
    <img
      loading="eager"
      src="assets/img/fullres/kreativwerkbank/kreativwerkbank_corporate.jpg"
    />
  </div>
</div>

<related-works>
  <card existingWork="upgrade-event"></card>
  <card existingWork="icc-hoehne"></card>
</related-works>
