import { Component } from '@angular/core';
import { WorkDetailsPageComponent } from '../components/work-details-page/work-details-page.component';
import { WorksService } from '../works.service';


@Component({
  selector: 'app-page-works',
  templateUrl: './page-works.component.html',
  styleUrls: ['./page-works.component.scss']
})
export class PageWorksComponent extends WorkDetailsPageComponent {
  constructor(worksSevice: WorksService) {
    super(worksSevice);
    super.workName = undefined;
  }

}
