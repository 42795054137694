<div class="max-width-container section-top-margin ">
  <div class="intro section-margin">
    <div class="about-portraits">
      <img loading="eager" class="main" src="assets/img/about/mette_boldt_1.jpg">
      <div class="mini-portrait-row" style="display: flex">
        <img class="mini-portrait" loading="eager" src="assets/img/about/mette_boldt_3.jpg">
        <img class="mini-portrait" loading="eager" src="assets/img/about/mette_boldt_2.jpg">
        <!-- <img class="mini-portrait" loading="eager" class="main" src="assets/img/about/mette_boldt_4.jpg"> -->
        <img class="mini-portrait" loading="eager" src="assets/img/about/mette_boldt_5.jpg">
      </div>
    </div>
    <div class="text-container text-with-img">
      <h1>About</h1>
      <p>I am a digital experience designer currently working as a consultant for <a href="https://www.capgemini.com/" target="_blank">Capgemini</a> in Berlin. Here, I work closely together with my colleagues to create a brand new design system for a desktop app for a client in the public sector. My main responsibility is creating user-centered designs using methods such as user interviews, UX workshops and user testing with interactive prototypes.</p>

      <p>I hold a Master of Arts in <a href="https://www.hfk-bremen.de/en/t/digitale-medien/n/digital-media" target="_blank">Digital Media</a> from University of the Arts Bremen; an inspiring place where I worked on innovative and thought-provoking projects, especially in the fields of virtual reality and speculative design.</p>

      <p>I grew up on Bornholm, Denmark, and have lived and studied in Aarhus and Stockholm before moving to Germany. My choice of education has always been dominated by curiosity, whereby my experience spans between research, art, design, and technology.

      <p>Living abroad gives and has given me a rewarding understanding of international cultures. Naturally, it has also improved my language skills, meaning I speak fluent Danish, English and German as well as some slightly shaky Swedish.</p>
      <p>Below is a short overview of my experience. For more detailed information, please <a routerLink='/contact'>contact me</a> for my full resume.</p>
    </div>
  </div>

  <div class="timeline text-container center section-margin">
    <h2>Work experience</h2>
    <div class="element">
        <span class="label">2018-present</span><br>
        <p><a href="https://www.capgemini.com/" target="_blank">Capgemini</a>, Berlin: <br>
        •	Lead Digital Experience Designer (2020-present) <br>
        •	Digital Experience Designer (2018-2020)</p>
    </div>
      <div class="element">
        <span class="label">2019</span><br>
        <p>Teacher | <a href="https://www.hfk-bremen.de/en" target="_blank">University of the Arts Bremen</a></p>
      </div>
      <div class="element">
        <span class="label">2017-2018</span><br>
        <p><a href="https://webbrand.de/" target="_blank">webbrand GmbH</a>, Bremen:<br>
        •	Web Designer & Developer (2017-2018)<br>
        •	Web Design Intern (2017)</p>
      </div>
      <div class="element">
        <span class="label">2017</span><br>
        <p>Freelance illustrator | <a href="https://publish.saxo.com/" target="_blank">Saxo Publish</a>, Denmark (Remote)</p>
      </div>
      <div class="element">
        <span class="label">2015</span><br>
        <p>Communication & Design Intern | Selfiejobs, Stockholm</p>
      </div>
  </div>

  <div class="timeline text-container center section-margin">
    <h2>Education</h2>
    <div class="element">
        <span class="label">2015-2018</span><br>
        <p>Master of Arts: <a href="https://digitalmedia-bremen.de/" target="_blank">Digital Media</a> | University of the Arts Bremen & University of Bremen</p>
    </div>
      <div class="element">
        <span class="label">2014</span><br>
        <p>Art & Design <a href="https://kunstogdesign.dk/" target="_blank">"Dagskole"</a> | Skolen for Kunst og Design Aarhus</p>
      </div>
      <div class="element">
        <span class="label">2011-2014</span><br>
        <p>Bachelor of Arts: <a href="https://bachelor.au.dk/medievidenskab/" target="_blank">Media Studies</a> | Aarhus University</p>
      </div>
  </div>

  <div class="timeline text-container center section-margin">
    <h2>Achievements</h2>
    <div class="element">
        <span class="label">2019</span><br>
        <p><a routerLink='/unwrap'>Unwrap</a> presented as lightning talk and exhibition at PUSH UX in Munich</p>
    </div>
      <div class="element">
        <span class="label">2018</span><br>
        <p><a routerLink='/unwrap'>Unwrap</a> selected for an international guest presentation at Computer Space in Sofia</p>
      </div>
      <div class="element">
        <span class="label">2018</span><br>
        <p><a routerLink='/you-shall-not-pass'>You Shall Not Pass: Non-Intrusive Feedback for Virtual Walls in VR Environments with Room-Scale Mapping</a> (group work) selected and presented as research conference paper at IEEE VR in Reutlingen</p>
      </div>
      <div class="element">
        <span class="label">2017</span><br>
        <p><a routerLink='/vrkid'>vrKid</a> selected for the <a href="https://www.flickr.com/photos/arselectronica/36946322222/in/album-72157688616394345/" target="_blank">VR Lab timeline</a> in the exhibition <a href="https://ars.electronica.art/center/en/exhibitions/new-views-of-humankind/" target="_blank">New Views of Humankind</a> at Ars Electronica in Linz</p>
      </div>
      <div class="element">
        <span class="label">2017</span><br>
        <p>HfK Hochschulpreis 1st prize Digital Media in Bremen for <a routerLink='/vrkid'>vrKid</a></p>
      </div>
  </div>



</div>
