import { Component } from '@angular/core';
import { WorkDetailsPageComponent } from '../components/work-details-page/work-details-page.component';
import { WorksService } from '../works.service';

@Component({
  selector: 'app-page-about',
  templateUrl: './page-about.component.html',
  styleUrls: ['./page-about.component.scss'],
})
export class PageAboutComponent extends WorkDetailsPageComponent {
  constructor(worksSevice: WorksService) {
    super(worksSevice);
    super.workName = undefined;
  }
}
