import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { PageAboutComponent } from './page-about/page-about.component';
import { PageContactComponent } from './page-contact/page-contact.component';
import { PageIndexComponent } from './page-index/page-index.component';
import { PageWorksComponent } from './page-works/page-works.component';
import { WorksCroquisComponent } from './works/works-croquis/works-croquis.component';
import { WorksIccHoehneComponent } from './works/works-icc-hoehne/works-icc-hoehne.component';
import { WorksKreativwerkbankComponent } from './works/works-kreativwerkbank/works-kreativwerkbank.component';
import { WorksSelectedDrawingsComponent } from './works/works-selected-drawings/works-selected-drawings.component';
import { WorksSpyComponent } from './works/works-spy/works-spy.component';
import { WorksThuridComponent } from './works/works-thurid/works-thurid.component';
import { WorksUnwrapComponent } from './works/works-unwrap/works-unwrap.component';
import { WorksUpgradeEventComponent } from './works/works-upgrade-event/works-upgrade-event.component';
import { WorksVrSoundComponent } from './works/works-vr-sound/works-vr-sound.component';
import { WorksVrkidComponent } from './works/works-vrkid/works-vrkid.component';
import { WorksYouShallNotPassComponent } from './works/works-you-shall-not-pass/works-you-shall-not-pass.component';
import { WorksInYourFaceComponent } from './works/works-in-your-face/works-in-your-face.component';

const routes: Routes = [
  { path: '', redirectTo: '/index', pathMatch: 'full' },
  { path: 'index', component: PageIndexComponent },
  { path: 'works', component: PageWorksComponent },
  { path: 'about', component: PageAboutComponent },
  { path: 'contact', component: PageContactComponent },
  { path: 'vrkid', component: WorksVrkidComponent },
  { path: 'croquis', component: WorksCroquisComponent },
  { path: 'thurid', component: WorksThuridComponent },
  { path: 'unwrap', component: WorksUnwrapComponent },
  { path: 'you-shall-not-pass', component: WorksYouShallNotPassComponent },
  { path: 'spy', component: WorksSpyComponent },
  { path: 'icc-hoehne', component: WorksIccHoehneComponent },
  { path: 'kreativwerkbank', component: WorksKreativwerkbankComponent },
  { path: 'upgrade-event', component: WorksUpgradeEventComponent },
  {
    path: 'surround-sounds-ability-to-lead-the-attention-of-the-viewer-in-vr',
    component: WorksVrSoundComponent,
  },
  {
    path: 'drawings-and-illustrations',
    component: WorksSelectedDrawingsComponent,
  },
  { path: 'in-your-face-design-for-public-transport', component: WorksInYourFaceComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), NgcCookieConsentModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const routingComponents = [
  PageIndexComponent,
  PageWorksComponent,
  PageAboutComponent,
  PageContactComponent,
  WorksVrkidComponent,
  WorksCroquisComponent,
  WorksThuridComponent,
  WorksUnwrapComponent,
  WorksYouShallNotPassComponent,
  WorksSpyComponent,
  WorksKreativwerkbankComponent,
  WorksUpgradeEventComponent,
  WorksVrSoundComponent,
  WorksSelectedDrawingsComponent,
  WorksInYourFaceComponent,
];
