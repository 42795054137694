import { Component, OnInit, Input } from '@angular/core';
import { ProjectType } from '../../../types/project-type';
import { WorkInfo } from '../../../types/WorkInfo';
import { WorksService } from '../../works.service';

@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  projectTypeClass: string;
  projectTypeText: string;

  @Input() existingWork: string;

  @Input() projectType: ProjectType;
  @Input() title = 'card title';
  @Input() routerLink = '/';
  @Input() imageLink = '/example.jpg';
  description: string;

  worksService: WorksService;

  constructor(worksService: WorksService) {
    this.worksService = worksService;
  }

  ngOnInit(): void {
    if (this.existingWork) {
      const work: WorkInfo = this.worksService.getSpecificWorkByName(this.existingWork);
      this.projectType = work.projectType;
      this.title = work.title;
      this.routerLink = work.routerLink;
      this.imageLink = work.imageLink;
      this.description = work.description;
      console.log('used existing work:' + work);
    }

    if (this.projectType === ProjectType.Speculative) {
      this.projectTypeClass = 'speculative-design';
      this.projectTypeText = 'Speculative Design';
    }
    if (this.projectType === ProjectType.Drawing) {
      this.projectTypeClass = 'drawing';
      this.projectTypeText = 'Drawing';
    }
    if (this.projectType === ProjectType.GameDesign) {
      this.projectTypeClass = 'game-design';
      this.projectTypeText = 'Game Design';
    }
    if (this.projectType === ProjectType.Research) {
      this.projectTypeClass = 'research';
      this.projectTypeText = 'Research';
    }
    if (this.projectType === ProjectType.WebDesign) {
      this.projectTypeClass = 'web-design';
      this.projectTypeText = 'Web Design';
    }
    if (this.projectType === ProjectType.Teaching) {
      this.projectTypeClass = 'teaching';
      this.projectTypeText = 'Teaching';
    }
  }
}
