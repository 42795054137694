<app-navi></app-navi>

<!-- <app-page-index></app-page-index> -->


<div class="max-width-container">

<!-- <card projectType=ProjectType.Speculative></card> -->
</div>

<router-outlet (activate)="onActivate($event)"></router-outlet>
<work-pagination></work-pagination>

<app-footer></app-footer>
