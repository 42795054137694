import { ProjectType } from './project-type';
export class WorkInfo {

  index: number;
  projectType: ProjectType;
  title: string;
  imageLink: string;
  routerLink: string;
  description: string;

  constructor(
    index: number,
    projectType: ProjectType,
    title: string,
    imageLink: string,
    routerLink: string,
    description: string
  ) {
    this.index = index;
    this.projectType = projectType;
    this.title = title;
    this.imageLink = imageLink;
    this.routerLink = routerLink;
    this.description = description;
  }
}
