import { Component, HostListener } from '@angular/core';
import { WorksService } from 'src/app/works.service';
import { WorkInfo } from 'src/types/WorkInfo';

@Component({
  selector: 'work-pagination',
  templateUrl: './work-pagination.component.html',
  styleUrls: ['./work-pagination.component.scss'],
})
export class WorkPaginationComponent {
  worksService: WorksService;
  previousLink: string;
  nextLink: string;

  isWorkPage: boolean;
  scrolledEnough: boolean;
  fixButtons: boolean;

  constructor(worksSevice: WorksService) {
    this.worksService = worksSevice;
    this.isWorkPage = false;
    this.scrolledEnough = false;
    this.fixButtons = false;
    this.worksService.currentWorkChange.subscribe((work: WorkInfo) => {
      this.update(work);
    });
  }

  update(currentWork: WorkInfo): void {
    if (currentWork) {
      this.previousLink = this.worksService.getPreviousWorkLink();
      this.nextLink = this.worksService.getNextWorkLink();
      this.isWorkPage = true;
    } else {
      this.isWorkPage = false;
    }
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event): void {
    const buttons = document.getElementById('paginationButtons');
    const y = event.currentTarget.scrollY;
    const windowHeight = window.innerHeight;
    const docHeight = document.body.clientHeight;
    if (y > 105) {
      this.scrolledEnough = true;
    } else {
      this.scrolledEnough = false;
    }

    if (y + windowHeight > docHeight - 200) {
      this.fixButtons = true;
    } else {
      this.fixButtons = false;
    }
  }

  shouldShow(): boolean {
    return this.isWorkPage && this.scrolledEnough;
  }
}
