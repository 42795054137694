import { Component } from '@angular/core';
import { WorkDetailsPageComponent } from '../../components/work-details-page/work-details-page.component';
import { WorksService } from '../../works.service';

@Component({
  selector: 'app-works-kreativwerkbank',
  templateUrl: './works-kreativwerkbank.component.html',
  styleUrls: ['./works-kreativwerkbank.component.scss'],
})
export class WorksKreativwerkbankComponent extends WorkDetailsPageComponent {
  constructor(worksSevice: WorksService) {
    super(worksSevice);
    super.workName = 'kreativwerkbank';
  }
}
