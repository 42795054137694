import { Component } from '@angular/core';
import { WorkDetailsPageComponent } from '../../components/work-details-page/work-details-page.component';
import { WorksService } from '../../works.service';

@Component({
  selector: 'app-works-selected-drawings',
  templateUrl: './works-selected-drawings.component.html',
  styleUrls: ['./works-selected-drawings.component.scss']
})
export class WorksSelectedDrawingsComponent extends WorkDetailsPageComponent {
  constructor(worksSevice: WorksService) {
    super(worksSevice);
    super.workName = 'selected-drawings';
  }
}
