import { Component } from '@angular/core';
import { WorkDetailsPageComponent } from '../../components/work-details-page/work-details-page.component';
import { WorksService } from '../../works.service';

@Component({
  selector: 'app-works-thurid',
  templateUrl: './works-thurid.component.html',
  styleUrls: ['./works-thurid.component.scss'],
})
export class WorksThuridComponent extends WorkDetailsPageComponent {
  constructor(worksSevice: WorksService) {
    super(worksSevice);
    super.workName = 'thurid';
  }
}
