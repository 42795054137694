import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule
} from 'ngx-cookieconsent';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardComponent } from './components/card/card.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeroVideoComponent } from './components/hero-video/hero-video.component';
import { InfoBoxItemComponent } from './components/info-box-item/info-box-item.component';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { NaviComponent } from './components/navi/navi.component';
import { QuoteComponent } from './components/quote/quote.component';
import { WorkDetailsPageComponent } from './components/work-details-page/work-details-page.component';
import { WorkPaginationComponent } from './components/work-pagination/work-pagination.component';
import { PageAboutComponent } from './page-about/page-about.component';
import { PageContactComponent } from './page-contact/page-contact.component';
import { PageIndexComponent } from './page-index/page-index.component';
import { PageWorksComponent } from './page-works/page-works.component';
import { WorksCroquisComponent } from './works/works-croquis/works-croquis.component';
import { WorksIccHoehneComponent } from './works/works-icc-hoehne/works-icc-hoehne.component';
import { WorksKreativwerkbankComponent } from './works/works-kreativwerkbank/works-kreativwerkbank.component';
import { WorksSelectedDrawingsComponent } from './works/works-selected-drawings/works-selected-drawings.component';
import { WorksSpyComponent } from './works/works-spy/works-spy.component';
import { WorksThuridComponent } from './works/works-thurid/works-thurid.component';
import { WorksUnwrapComponent } from './works/works-unwrap/works-unwrap.component';
import { WorksUpgradeEventComponent } from './works/works-upgrade-event/works-upgrade-event.component';
import { WorksVrSoundComponent } from './works/works-vr-sound/works-vr-sound.component';
import { WorksVrkidComponent } from './works/works-vrkid/works-vrkid.component';
import { WorksYouShallNotPassComponent } from './works/works-you-shall-not-pass/works-you-shall-not-pass.component';
import { RelatedWorksComponent } from './related-works/related-works.component';
import { WorksInYourFaceComponent } from './works/works-in-your-face/works-in-your-face.component';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'www.metteboldt.com',
  },
  palette: {
    popup: {
      background: '#000',
    },
    button: {
      background: '#f1d600',
    },
  },
  theme: 'classic',
  type: 'info',
  content: {
    message:
      'This website is built using Angular. It is not tracking you. Once you acknowledge this, a single cookie will save your consent.',
  },
  layout: 'my-custom-layout',
  layouts: {
    'my-custom-layout': '{{messagelink}}{{compliance}}',
  },
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}}</span>`,
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HeroVideoComponent,
    QuoteComponent,
    CardComponent,
    NaviComponent,
    FooterComponent,
    PageIndexComponent,
    PageWorksComponent,
    PageAboutComponent,
    PageContactComponent,
    WorksVrkidComponent,
    InfoBoxComponent,
    InfoBoxItemComponent,
    WorksCroquisComponent,
    WorksThuridComponent,
    WorksUnwrapComponent,
    WorksYouShallNotPassComponent,
    WorksSpyComponent,
    WorksIccHoehneComponent,
    WorksKreativwerkbankComponent,
    WorksUpgradeEventComponent,
    WorksVrSoundComponent,
    WorksSelectedDrawingsComponent,
    WorkPaginationComponent,
    WorkDetailsPageComponent,
    RelatedWorksComponent,
    WorksInYourFaceComponent,
  ],
  imports: [
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserModule,
    AppRoutingModule,
  ],
  providers: [NgcCookieConsentModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
