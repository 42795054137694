import { Component } from '@angular/core';
import { WorkDetailsPageComponent } from '../../components/work-details-page/work-details-page.component';
import { WorksService } from '../../works.service';

@Component({
  selector: 'app-works-spy',
  templateUrl: './works-spy.component.html',
  styleUrls: ['./works-spy.component.scss']
})
export class WorksSpyComponent extends WorkDetailsPageComponent {
  constructor(worksSevice: WorksService) {
    super(worksSevice);
    super.workName = 'spy';
  }
}
