import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {

  @Input() quote = "Quote text goes here.";
  @Input() person = "Tester";

  constructor() { }

  ngOnInit(): void {
  }

}
