<div class="viewport section-margin">
  <div class="cover-photo" style="background-image: url('assets/img/fullres/unwrap/mette_boldt_unwrap_a.jpg')">
  </div>
  <div class="max-width-container vertical-center">
    <info-box title="Unwrap">
      <info-box-item label="Category">
        <div class="label category speculative-design">Speculative Design</div>
      </info-box-item>
      <info-box-item label="Year">
        2018
      </info-box-item>
      <info-box-item label="Institution">
        University of the Arts Bremen
      </info-box-item>
      <info-box-item label="Work mode">
        Individual
      </info-box-item>
      <info-box-item label="Skills">
        Processing <span class="item-divider">//</span> Android Studio <span class="item-divider">//</span> Fusion 360 <span class="item-divider">//</span> 3D Printing <span class="item-divider">//</span> Premiere Pro
      </info-box-item>
      <info-box-item label="Achievements">
        Lightning Talk at Push UX 2019, Munich <span class="item-divider">//</span> International guest presentation at Computer Space 2018, Sofia
      </info-box-item>
    </info-box>
  </div>
</div>

<div class="max-width-container section-margin section-margin">
  <div class="text-container center">
    <h2>Summary</h2>
    <p class="standard-margin">Unwrap is a device for smartphones which shows other people in close physical proximity which app is currently in use. The device directly addresses the opaque character of the smartphone as opposed to devices and objects with limited functions, such as a city map or a newspaper. The aim of the design is to enable discussions about how a more transparent use of technologies could affect our everyday lives.</p>

     <p class="standard-margin"> I created a functional model, where an android app collects information about the currently used app and sends the information via Wi-Fi to a Raspberry Pi connected to a display. The case including hinge for proper viewing angels was produced using a 3D printer. For evaluation, I used the object myself for a few weeks, and I held test sessions where two different testers tried using the device in public spaces while I observed and conducted interviews. The project led to many interesting conversations about privacy and technology. Some quotes from testers as well as bystanders are found on this page.</p>

    <div class="section-margin">
      <quote
        quote="The thought crossed my mind: Well, if I open Tinder and he can see that, this could come across as flirting. He would be like: 'Oh, so you are looking for somebody, huh? Put away your app, we can talk.'"
        person="A tester">
      </quote>
    </div>

    <div class="img-row-one section-margin"><iframe src="https://player.vimeo.com/video/280621828" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="width: 100%;"></iframe></div>

    <p class="standard-margin">Unwrap was my master thesis from University of the Arts Bremen. Read the full thesis for extensive documentation of how the design came to be and what I learned from it.</p>
    <a href="assets/pdf/mette_boldt_unwrap_master_thesis.pdf" target="_blank">
      <button>Read the master thesis</button>
    </a>
  </div>
</div>

<div class="max-width-container-img section-margin">
  <div class="img-row-two standard-margin">
    <img loading="eager" src="assets/img/fullres/unwrap/mette_boldt_unwrap_e.jpg">
    <img loading="eager" src="assets/img/fullres/unwrap/mette_boldt_unwrap_f.jpg">
  </div>
</div>

<div class="max-width-container section-margin section-margin">
  <div class="text-container center">
    <quote
      quote="I’m thinking about the university or something... if I'm taking notes at a seminar, that the lecturer doesn’t think I’m being totally impolite and on Facebook or something"
      person="A student">
    </quote>
  </div>
</div>


<div class="max-width-container section-margin section-margin">
  <div class="text-container center">
    <h2>The problem</h2>
    <p class="standard-margin">According to sociologist Erving Goffman ("The Presentation of Self in Everyday Life", 1956), we constantly create mutual definitions of the social situations we find ourselves in, and we behave in certain ways depending on what would be proper or beneficial behavior in that specific situation. But how are we supposed to judge proper behavior in social situations where digital content through for example smartphones affects single individuals without being visible to other people?</p>

    <p class="standard-margin">With most specialized devices and objects, some amount of use context is always communicated to the surroundings. Using a city map means navigating, using a notebook means taking notes, using a Gameboy means playing games. With the smartphone, no such context is offered since the very same device can take on countless functions. The assumption of this project is that the lack of transparency challenges our perceptions of each other in everyday life and could lead to misunderstandings. Therefore, I took on the following challenge:</p>

    <p class="section-margin emphasis">How might I use design as a means to explore the influence a more transparent use of digital technologies could have on the way we interact with each other in everyday life?</p>

    <quote
      quote="I have considered whether one has better understanding of why someone is on the phone because of the device. You know, when someone is currently checking emails, if that could be important."
      person="A woman on a train">
    </quote>

  </div>
</div>

<div class="max-width-container-img section-margin">
  <div class="img-row-two standard-margin">
    <img loading="eager" src="assets/img/fullres/unwrap/mette_boldt_unwrap_b.jpg">
    <img loading="eager" src="assets/img/fullres/unwrap/mette_boldt_unwrap_c.jpg">
  </div>

  <div class="img-row-three section-margin">
    <img loading="eager" src="assets/img/fullres/unwrap/mette_boldt_unwrap_d.jpg">
    <img loading="eager" src="assets/img/fullres/unwrap/mette_boldt_unwrap_i.jpg">
    <img loading="eager" src="assets/img/fullres/unwrap/mette_boldt_unwrap_j.jpg">
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>The solution</h2>
    <p class="standard-margin">I decided to approach my challenge through a speculative device which provides the transparent use context the smartphone eliminated. I imagined a world where a transparent smartphone use is the norm, and I wanted to discuss with others what would happen if the development of technology would change directions towards more openness rather than closedness. </p>

    <p class="section-margin">Before settling on the final design of Unwrap, I considered different ways of providing transparency. Below are three different approaches I considered.</p>
  </div>
</div>

<div class="max-width-container-img section-margin">
  <div class="img-row-three section-margin">
    <img loading="eager" src="assets/img/fullres/unwrap/mette_boldt_unwrap_process_sketch_01.jpg">
    <img loading="eager" src="assets/img/fullres/unwrap/mette_boldt_unwrap_process_sketch_03.jpg">
    <img loading="eager" src="assets/img/fullres/unwrap/mette_boldt_unwrap_process_sketch_02b.jpg">
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h3 class="standard-margin">The low-tech approach</h3>
    <p class="section-margin">I believe the best ideas often start simple. My first idea was to simply attach physical figurines to the top of the phone to illustrate the current purpose of it. I liked the fact that this idea was analog, as a contrast to the smartphone itself. However, I decided to consider other options due to the fact that this solution would require much manual effort for the phone user. I wanted the information to <i>just be there</i>, the same way context information is <i>just there</i> when, for example, a person is reading a newspaper.</p>

    <h3 class="standard-margin">The high-tech approach</h3>
    <p class="standard-margin">For a long time, I though I wanted to do something with augmented reality. One idea I had was an AR application which communicates with other digital devices nearby and thereby gets information about the current purposes of the devices. Visual representations of these would then be augmented onto the real world together with additional info boxes.</p>

    <p class="section-margin">This idea obviously had the same problem as the first idea: The context information is not <i>just there</i> when an extra device is needed. The main reason I left this idea is that it seemed wrong to put the technical responsibility of retrieving the information on the bystanders. This solution would exclude less technical people from the social context.</p>

    <h3 class="standard-margin">The attached display approach</h3>
    <p class="section-margin">I decided to go for a solution where the app information is available on a display attached to the smartphone and facing the surroundings. The idea was that the position of the device helps making it easier to understand what the device does (I later made an alternative wearable design, see below). The digital character made sure I could update the content automatically.</p>
  </div>
</div>

<div class="max-width-container-img section-margin">
  <div class="img-row-two section-margin">
    <img loading="eager" src="assets/img/fullres/unwrap/mette_boldt_unwrap_h.jpg">
    <img loading="eager" src="assets/img/fullres/unwrap/mette_boldt_unwrap_l.jpg">
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <div class="standard-margin"><quote
      quote="If it was more than one person seeing the device then they would all of a sudden start to whisper and point fingers. That’s really fun and that’s when I started Tinder and they’re like “no, she didn’t!”"
      person="A tester">
    </quote></div>

    <quote
        quote="If I bend myself and do other stuff than I normally would just for you to see, then it’s just fake and not myself. [...] then I can also just overwrite this screen, say I’m reading National Geographic, when actually I’m, ehm, looking for cock pics."
        person="A tester">
      </quote>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2 class="standard-margin">Why only show the app?</h2>
    <p class="standard-margin">A question many people have asked me regarding the design of Unwrap is this: If this is a speculative device anyway, then why do you only show the app? Would it not shock more if you displayed even more data?</p>
    <p class="standard-margin">Yes, that would definitely shock more. But that does not necessarily mean that the design would have a bigger impact on people.</p>

    <p class="standard-margin">If I had shown more data - say, if I had mirrored the phone display onto the external display - I think it would have been too easy to argue against the device, to say it is just crazy and never think about it again. I wanted people to be able to picture a world where something like Unwrap is the norm.</p>

    <p class="standard-margin">Furthermore, I was interested in discussions based on a level of transparency we already know from other media: When someone is reading a map, bystanders can tell the person is navigating. But no one will be able to tell exactly where the person is going, nor why the person wants to go there.</p>

    <div class="standard-margin">
      <quote
        quote="I always feel a bit honored when people look at the book I’m currently reading. Maybe one could transfer that to this device."
        person="A student">
      </quote>
    </div>

    <quote
      quote="It is a private sphere what you have on the phone and when someone sees what you are doing with it, it’s a strange feeling. But if you only see the app it’s not that bad."
      person="A young man on a train">
    </quote>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <!-- <div class="timeline section-margin">
      <h2>The process</h2>
      <div class="element">
          <span class="label">Research & Brainstorming</span><br>
          <p>Gathering inspiration and researching everything from AR to micro-sociology</p>
      </div>
      <div class="element">
        <span class="label">First sketches</span><br>
        <p>Exploring different approaches to the overall idea</p>
      </div>
      <div class="element">
        <span class="label">Making the device</span><br>
        <p>Pragramming an Android app, establishing a connection to a Raspberry Pi, soldering wires, 3D-modeling, and 3D-printing a case</p>
      </div>
      <div class="element">
        <span class="label">Evaluation</span><br>
        <p>Using the device in real life, having others test it, and observing and interviewing bystanders</p>
      </div>
      <div class="element">
        <span class="label">Presentations</span><br>
        <p>Presenting the device to larger audiences</p>
      </div>
    </div> -->



    <!-- <h2>Research & Brainstorming</h2>
    <p class="standard-margin">In the beginning of the design process, I kept two separate diaries for the ideation: One was a collection of brainstorming topics and specific project ideas, while the other was a visual diary of my days developing a concept. Clearly, some days felt more successful than others.</p>

    <p class="standard-margin">For quite a while, I was certain I wanted to do something in the field of augmented reality. I was (and still am) extremely interested in this fast-moving technology, which has a lot of potential. Watching <a href="https://vimeo.com/166807261" target="_blank">Hyper-Reality</a>, a first-person short film which depicts a world where literally everything is dominated by augmented content, I started thinking about how technology affects the way we interact with each other. I have always been interested in sociology and especially fascinated by Erving Goffman's theory of interaction in everyday life. To keep it short, it is about how we people mutually define the social situations we are in and act certain roles depending on which behavior would be proper or beneficial in that situation. I thought:</p>

    <p class="standard-margin" style="background-color: #f5f5f5; padding: 1rem;">How are we supposed to judge proper behavior in social situations, when digital content affects individuals without being visible to everyone in the gathering?</p>

    <p class="section-margin">I realised I did not have to wait for the dominance of AR in order to find out. I could simply focus my design on a device which has this impact already: The smartphone. I decided to explore what would happen if I brought back the transparency, which disappeared with the smartphone, to social situations in real life.</p> -->


  </div>
</div>



<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>Making the device</h2>
    <p class="standard-margin">It was important for me to build a model which actually works. Only then could I test the device and get a real feeling of how it is perceived.


      I achieved this by having an android app running in the background on the smartphone, which detects the current foreground app and sends the information via wifi (open sound control) to a Raspberry Pi. The Raspberry Pi displays the information on a small display through a simple Processing application. The display has IPS technology in order to ensure a good outdoor visibility even at extreme viewing angles.</p>

    <div class="section-margin">
      <quote
      quote="If for example the person in front of me is texting on WhatsApp the whole time or something, then I would think: Doesn’t he have something better to do? Or if I see that he is currently using some app which is needed for work - then I would think exactly the same thing."
      person="A man on a train">
      </quote>
    </div>

    <div class="img-row-one section-margin">
      <img loading="eager" src="assets/img/fullres/unwrap/mette_boldt_unwrap_overview.png">
    </div>

    <p class="standard-margin">The case for unwrap was modelled in Fusion 360 and printed in PLA on a prusa i3 3D printer. The case was carefully sanded and spraypainted for a smooth finish and attached to a phone cover with a hinge.</p>

    <div class="section-margin">
      <quote
        quote="I could imagine that it develops so far that the device itself could also become luxurious and that people who have more money, like with I don’t know iPhone X or something, would have a gold-framed version which is smaller."
        person="A student">
      </quote>
    </div>

  </div>
</div>

<div class="max-width-container-img section-margin">
  <div class="img-row-three standard-margin">
    <img src="assets/img/fullres/unwrap/Screen Shot 2018-06-22 at 16.17.37.png">
    <img src="assets/img/fullres/unwrap/Screen Shot 2018-06-22 at 16.17.26.png">
    <img src="assets/img/fullres/unwrap/IMG_20180425_165608 copy.jpg">
  </div>

  <div class="img-row-three standard-margin">
    <img src="assets/img/fullres/unwrap/IMG_20180425_163141 copy.jpg">
    <img src="assets/img/fullres/unwrap/IMG_20180425_165407 copy.jpg">
    <img src="assets/img/fullres/unwrap/IMG_20180425_165508 copy.jpg">
  </div>

</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
  <p class="standard-margin">As mentioned, the idea with the device being attached to the top of the phone was that the close physical proximity would help bystanders understand the connection between the smartphone and the content on the device. However, I also made an alternative design, where the device can be worn as a kind of badge. This was more practical due to the size of the device and the cable giving the raspberry pi power from a power bank. During testing, I learned that the connection between device and smartphone was understood for the wearable version as well, as long as one was actually holding the phone. </p>

    <div class="img-row-one section-margin">
      <img loading="eager" src="assets/img/fullres/unwrap/mette_boldt_unwrap_g.jpg">
    </div>

    <div class="standard-margin">
      <quote
        quote="[...] Maybe also something like Pokémon Go. People are like “mmmm, that hype is over, she’s still playing it, that’s so 2016.” I guess [with Unwrap] it would be different. Then you would see 'okay, we are all stuck in 2016, can we play together?'"
        person="A tester">
      </quote>
    </div>


    <div class="section-margin">
      <quote
        quote="Whether it’s “I’m currently using WhatsApp” or “I’m currently using Google” or I’m currently using something. It’s always the same. It has nothing personal to it."
        person="A woman on a train">
      </quote>
    </div>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">

    <h2>Evaluation</h2>
    <p class="standard-margin">I used the device with my phone for a few weeks, encountering different social situations such as riding the bus, studying at university, being at work and going to a birthday party. I also had two different people try the device in public in test sessions. Through observation and interviews, I encountered interesting discussions about how the device was perceived and how bringing transparency back to media usage could affect the way we use technology and interact with each other in everyday life.</p>

    <p class="standard-margin">The evaluation gave me a wide variety of different opinions. Though many expressed skepticism especially concerning privacy, most people were also able to see advantages of using the device. A few were even outright fond of the device, while some had no strong feelings towards it. But no matter what, they all had something to say.</p>

    <p class="standard-margin">What touched me the most in the process of making Unwrap is probably how the device changed my own view. I started out thinking that this was a great speculative idea, to enable discussions and bring awareness to the fact that technology has changed our face to face interactions. But I never thought it should become a serious product idea. I am not 100% sure anymore. It was absolutely horrible to use the device during those few weeks, but to be honest, the horrible part was not the fact that others could see my apps. The horrible part was the attention I got for being the only one using it. If it were just normal and everyone had it, I would not be a freak. The device definitely affected which apps I used in public. But is that bad? It could definitely also have advantages if the line between private space and public space would get harder. One would still use Grindr - but at home, maybe.</p>

    <div class="standard-margin">
      <quote
        quote="It’s intrusive. It’s like, it shows something though no one asked whether I’m interested in that. And therefore, I think of advertisement because advertisement works by… You don’t ask, you get."
        person="A man on a train">
      </quote>
    </div>

    <div class="standard-margin">
      <quote
        quote="Normally with my phone no one sees what I’m doing, but with this device people can just be like interested in what she’s doing and just ask her, like, “can I join you, playing the game?"
        person="A student">
      </quote>
    </div>

  </div>
</div>






<div class="max-width-container section-margin">
  <div class="text-container center">


    <div class="img-row-one section-margin">
      <img loading="eager" src="assets/img/fullres/unwrap/mette_boldt_unwrap_k.jpg">
    </div>

    <div class="standard-margin">
      <quote
        quote="You don’t see it that often, right, it’s a matter of getting used to it. I mean, if everyone had it, you would get used to it quickly."
        person="A woman on a train">
      </quote>
    </div>

    <div class="standard-margin">
      <quote
        quote="Sure some would use it for self-profiling. There are always those scum."
        person="A street recruiter">
      </quote>
    </div>

    <quote
      quote="It could create a better world, right. It could be a motivation to … be a better person with ... higher quality interests for spending my time more reasonably, because people can tell and people will judge, so …Yeah. But then again, I think I would hate it."
      person="A tester">
    </quote>
  </div>
</div>


<div class="max-width-container standard-margin">
  <div class="text-container center">
    <h2>Presentations</h2>
    <p class="section-margin">I had the honor of presenting unwrap and what I learned evaluating it at the PUSH UX conference 2019 in Munich and at Computer Space in Sofia 2018.</p>
  </div>
</div>

<div class="max-width-container-img standard-margin">
  <div class="img-row-three standard-margin">
    <img src="assets/img/fullres/unwrap/push/49034026757_887ebe679d_k.jpg">
    <img src="assets/img/fullres/unwrap/push/49033302033_20fbaab8a0_k.jpg">
    <img src="assets/img/fullres/unwrap/push/20191024_130403.jpg">
  </div>
</div>


<div class="max-width-container section-margin">
  <div class="text-container center">
      <quote
        quote="I may be the least photogenic person on earth, but I'm good at presenting."
        person="Mette">
      </quote>
  </div>
</div>


<div class="max-width-container section-margin">
  <div class="text-container center img-row-one">
    <img src="assets/img/fullres/unwrap/mette_boldt_computer_space.jpg">
  </div>
</div>


<related-works>
  <card existingWork="vrKid"></card>
  <card existingWork="in-your-face-design-for-public-transport"></card>
</related-works>
