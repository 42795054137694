<div *ngIf="isLoading" class="intro-video">
  <div class="video-placeholder">
    <div class="fa fa-spinner fa-spin" aria-hidden="true">
      <p>Loading...</p>
      <div class="canvas">
        <div class="spinner p1"></div>
        <div class="spinner p2"></div>
        <div class="spinner p3"></div>
        <div class="spinner p4"></div>
      </div>
    </div>
  </div>
</div>
<div class="intro-video">
  <video
    #videoPlayer
    playsinline
    autoplay
    muted
    loop
    [ngClass]="{ loading: isLoading }"
    id="heroVideo"
    preload="auto"
    (click)="toggleVideo()"
    allow="autoplay"
    onloadedmetadata="this.muted = true"
  >
    <source src="assets/video/mette_boldt_intro_video.mp4" type="video/mp4" />
    Your browser does not support HTML5 video.
  </video>
</div>
<div class="clickToPlay" *ngIf="notPlaying && !isLoading" (click)="toggleVideo()">If the video does not play automatically, click here</div>
