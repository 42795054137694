<div class="viewport">
    <div class="max-width-container">
      <app-hero-video></app-hero-video>
    </div>
  <div class="max-width-container absolute works-cover">
    <div class="text-container center h1-container">
      <h1>Mette Boldt</h1>
      <p>
        Digital experience designer with a passion for technology, art and games
      </p>
    </div>
  </div>
  <img
    #chevron
    (click)="scrollToElement(chevron)"
    class="scroll-chevron"
    src="assets/icons/chevron-down.svg"
  />
</div>

<div class="max-width-container works-title standard-margin">
  <div class="text-container center">
    <h2>Selected work</h2>
    <button routerLink="/works" class="inline-button">More work</button>
  </div>
</div>
<div class="max-width-container-img standard-margin">
  <card existingWork="vrKid"></card>
  <card existingWork="you-shall-not-pass"></card>
  <card existingWork="unwrap"></card>
  <card existingWork="spy"></card>
  <card existingWork="upgrade-event"></card>
  <card existingWork="in-your-face-design-for-public-transport"></card>
  <card existingWork="thurid"></card>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>About me</h2>
    <p>
      I am a Digital Experience Designer currently working as a consultant for
      <a href="https://www.capgemini.com/" target="_blank">Capgemini</a> in
      Berlin, where my current task is to design a new design system for a desktop application in the public sector.</p>

      <p>My professional background is characterized by an artistic as well as research-oriented way of working. Both mindsets unite in my work as a designer, where I base my creative work on interviews, observations and tests.
    </p>

    <div class="standard-margin">
      <quote
        quote="I’m a bit of a perfectionist. It’s a blessing and a curse. But lately, I’ve started to embrace it, and I'm learning how to combine that part of me with an iterative approach to my work as a designer."
        person="Mette"
      >
      </quote>
    </div>

    <button routerLink="/about">More about me</button>
  </div>
</div>
