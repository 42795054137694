import { Component } from '@angular/core';
import { WorkDetailsPageComponent } from '../../components/work-details-page/work-details-page.component';
import { WorksService } from '../../works.service';

@Component({
  selector: 'app-works-upgrade-event',
  templateUrl: './works-upgrade-event.component.html',
  styleUrls: ['./works-upgrade-event.component.scss'],
})
export class WorksUpgradeEventComponent extends WorkDetailsPageComponent {
  constructor(worksSevice: WorksService) {
    super(worksSevice);
    super.workName = 'upgrade-event';
  }
}
