import { Component } from '@angular/core';
import { WorkDetailsPageComponent } from '../../components/work-details-page/work-details-page.component';
import { WorksService } from '../../works.service';

@Component({
  selector: 'app-works-icc-hoehne',
  templateUrl: './works-icc-hoehne.component.html',
  styleUrls: ['./works-icc-hoehne.component.scss']
})
export class WorksIccHoehneComponent extends WorkDetailsPageComponent {
  constructor(worksSevice: WorksService) {
    super(worksSevice);
    super.workName = 'icc-hoehne';
  }
}
