<footer>
  <div class="max-width-container">
    <div>
      <div class="label contact mail">
        metteboldt [at] hotmail [dot] com
      </div>
      <a href="https://www.linkedin.com/in/metteboldt" class="label contact linkedin" target="_blank">
        www.linkedin.com/in/metteboldt
      </a>
    </div>
    <div>
      <div class="label secondary">
        All rights reserved. Copyright © Mette Boldt 2014 - 2020
      </div>
    </div>
  </div>
</footer>
