<div class="viewport">
  <div class="max-width-container section-top-margin">
    <div class="text-container center">
      <h1>Contact</h1>
      <p class="standard-margin">Please get in touch if you would like to get to know me better.</p>
      <div class="label contact mail">
        metteboldt [at] hotmail [dot] com
      </div>
      <a href="https://www.linkedin.com/in/metteboldt" class="label contact linkedin" target="_blank">
        www.linkedin.com/in/metteboldt
      </a>
    </div>
  </div>
</div>
