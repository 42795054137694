<div class="viewport section-margin">
  <div class="cover-photo" style="background-image: url('assets/img/fullres/upgrade-event/mette_boldt_upgrade_event_mockup.jpg')">
  </div>
  <div class="max-width-container vertical-center">
    <info-box title="Upgrade Event">
      <info-box-item label="Category">
        <div class="label category web-design">Web Design</div>
      </info-box-item>
      <info-box-item label="Year">
        2017-2018
      </info-box-item>
      <info-box-item label="Job">
        webbrand gmbh
      </info-box-item>
      <info-box-item label="Skills">
        Web design <span class="item-divider">//</span> Html <span class="item-divider">//</span> CSS <span class="item-divider">//</span> Javascript
      </info-box-item>
    </info-box>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center section-margin">
    <h2>Introduction</h2>
    <p class="standard-margin">Upgrade Event was one of the websites I had the most fun working on during my time at <a href="https://webbrand.de/" target="_blank">webbrand GmbH</a>. The stylish, flashy corporate design was a pleasure to adapt into a web design, and the technical implementation including the parallax scrolling effect and elegant navigation interactions was a great challenge.</p>
    <a href="https://www.upgrade-event.de/" target="_blank"><button >To the website</button></a>
  </div>

  <div class="img-row-one standard-margin">
    <img loading="eager" src="assets/img/fullres/upgrade-event/mette_boldt_upgrade_event_1.png">
  </div>

  <div class="img-row-one standard-margin">
    <img loading="eager" src="assets/img/fullres/upgrade-event/mette_boldt_upgrade_event_2.png">
  </div>

</div>

<related-works>
  <card existingWork="icc-hoehne"></card>
  <card existingWork="kreativwerkbank"></card>
</related-works>
