import { Component, OnInit } from '@angular/core';
import { WorksService } from '../works.service';

@Component({
  selector: 'app-page-index',
  templateUrl: './page-index.component.html',
  styleUrls: ['./page-index.component.scss'],
})
export class PageIndexComponent implements OnInit {
  worksService: WorksService;

  constructor(worksSevice: WorksService) {
    this.worksService = worksSevice;
  }

  ngOnInit(): void {
    this.worksService.setCurrentWork(undefined);
  }

  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
}
