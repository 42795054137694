import { Component, OnInit } from '@angular/core';
import { WorksService } from '../../works.service';

@Component({
  selector: 'app-work-details-page',
  templateUrl: './work-details-page.component.html',
  styleUrls: ['./work-details-page.component.scss'],
})
export class WorkDetailsPageComponent implements OnInit {
  worksService: WorksService;
  workName: string;

  constructor(worksSevice: WorksService) {
    this.worksService = worksSevice;
  }

  ngOnInit(): void {
    this.worksService.setCurrentWork(this.workName);
  }
}
