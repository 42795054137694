import { ElementRef } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hero-video',
  templateUrl: './hero-video.component.html',
  styleUrls: ['./hero-video.component.scss'],
})
export class HeroVideoComponent implements OnInit, AfterViewInit  {

  isLoading: boolean;
  notPlaying: boolean;

  @ViewChild('videoPlayer') videoplayer: ElementRef;


  constructor() {
    this.isLoading = true;
  }

  ngOnInit(): void {
    const vid = document.getElementById('heroVideo');
    vid.onplay = () => {
      this.isLoading = false;
      this.notPlaying = false;
    };
    vid.oncanplay = () => {
      this.isLoading = false;
    };
    vid.onerror = () => {
      console.log('an error occurred while laoding the video');
    };
  }

  ngAfterViewInit(): void {
    const promise = document.querySelector('video').play();

    if (promise !== undefined) {
      promise.then(_ => {
        this.notPlaying = false;
      }).catch(error => {
        this.notPlaying = true;
      });
    }
  }


  toggleVideo(): void {
    this.videoplayer.nativeElement.play();
    console.log('toggling video play');
    this.notPlaying = false;
  }
}
