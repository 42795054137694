<div class="viewport section-margin">
  <div class="cover-photo" style="background-image: url('assets/img/fullres/spy/boldt_etal_spy_1.jpg')">
  </div>
  <div class="max-width-container vertical-center">
    <info-box title="S.P.Y">
      <info-box-item label="Category">
        <div class="label category game-design">Game Design</div>
      </info-box-item>
      <info-box-item label="Year">
        2017
      </info-box-item>
      <info-box-item label="Institution">
        University of Bremen
      </info-box-item>
      <info-box-item label="Work mode">
        Group work
      </info-box-item>
      <info-box-item label="Skills">
        Game design <span class="item-divider">//</span> Unity <span class="item-divider">//</span> 3D texturing <span class="item-divider">//</span> VR
      </info-box-item>
    </info-box>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center section-margin">
    <h2>Summary</h2>
    <p class="standard-margin">S.P.Y is a sci-fi virtual reality game which helps treating and preventing back pain. In the game, the player is a spy working for the intergalactic, top-secret enterprise ‘Space Project Y,’ which specializes in infiltrating alien cultures by having agents imitate their behaviour wearing a high-tech space suit.</p>

    <p>Back exercises are implemented as a natural part of the game design, where the exercises inspired the aliens and their movements. Thereby, the exercises are the means of imitating and communicating with alien species. The game was built in Unity for the HTC Vive VR headset.</p>
  </div>

  <div class="img-row-one section-margin">
    <img loading="eager" src="assets/img/fullres/spy/boldt_etal_spy_outside.jpg">
  </div>

  <div class="text-container center section-margin">

    <h2>The Problem</h2>
    <p class="standard-margin">Back pain is a very common condition, which most people will experience at some point in their lives. In most cases, back pain is the result of inactivity or unhealthy sitting positions and can be treated with physical exercises.</p>

    <p class="standard-margin">The main problem? Physical exercises are boring. We wanted to find a way of making back exercises fun and asked ourselves:</p>

    <p class="emphasis">How might we create a motion-based game, where exercises for back pain are a fun and natural part of the game experience?</p>
  </div>

  <div class="text-container center section-margin">

    <h2>The Solution</h2>
    <p class="section-margin">Researching back exercises, we realized that while they are definitely good for the back, some of them also look funny when performed. The strange movements inspired us to design alien species who look like they could move that way. These movements became the main means of interaction in our VR game. We consulted a physiotherapist in order to achieve the optimal effect on back pain with the exercises used in the game. </p>

    <iframe loading="eager" width="672" height="378" src="https://www.youtube.com/embed/Q4iTQS5wjaU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="width: 100%;"></iframe>

  </div>
</div>



<div class="max-width-container section-margin">
  <div class="text-container center section-margin">
    <p class="standard-margin">S.P.Y is a room-scale VR game, meaning the player can move around freely. Replicating real-life movement, this means of locomotion offers great immersion. It also meant we had to limit the size of the in-game environment to the size of the trackable area of the HTC Vive. The movements, which were the inspiration for the alien species in the prototype, were visualized in a head-up display.</p>
  </div>
</div>

<div class="max-width-container-img section-margin img-row-three">
  <img loading="eager" src="assets/img/fullres/spy/boldt_etal_spy_hall.jpg">
  <img loading="eager" src="assets/img/fullres/spy/boldt_etal_spy_brain_picture_fred.jpg">
  <img loading="eager" src="assets/img/fullres/spy/boldt_etal_spy_brain_picture.jpg">
</div>

<div class="max-width-container section-margin">
  <div class="text-container center section-margin">
    <h2>Contributions</h2>
    <p>S. P. Y was an interdisciplinary group project between the University of the Arts Bremen and the University of Bremen. We were 14 students in total. Among other things, I played a central role in the development of game concept, character design and texturing, as well as game art including retexturing and using existing 3D models to create the atmosphere and spacious feeling in our game.</p>
  </div>
</div>
  <div class="max-width-container img-row-one section-margin">
    <img loading="eager" src="assets/img/fullres/spy/boldt_etal_spy_lab.jpg">
  </div>

  <div class="max-width-container section-margin">
    <div class="text-container center section-margin">
      <p>Following the development of the game prototype for S.P.Y, we produced a successful research paper on collision feedback for virtual walls in VR environments with room-scale mapping. For more information, see the page <a routerLink="/you-shall-not-pass">You Shall Not Pass</a>.</p>
    </div>
  </div>

  <related-works>
    <card existingWork="you-shall-not-pass"></card>
    <card existingWork="thurid"></card>
    <card existingWork="vr-sound"></card>
    <card existingWork="vrKid"></card>
  </related-works>
