<div class="max-width-container section-top-margin section-margin">

  <div class="text-container center">
    <h1 class="">Work</h1>
    <p class="standard-margin">Below is a selection of my work from recent years.</p>
    <p class="emphasis standard-margin">Note that I cannot present any work from my current job at Capgemini here due to non-disclosure agreements. It is a shame, because I have made great things there such as a brand new design system for a complex web-based desktop application.<p>
    <p>Please <a routerLink='/contact'>contact me</a> to talk about my accomplishments and learnings as a Digital Experience Designer at Capgemini in an anonymized fashion.</p>
  </div>
</div>

<div class="max-width-container-img standard-margin">
  <card existingWork="vrKid"></card>
  <card existingWork="you-shall-not-pass"></card>
  <card existingWork="unwrap"></card>
  <card existingWork="spy"></card>
  <card existingWork="upgrade-event"></card>
  <card existingWork="in-your-face-design-for-public-transport"></card>
  <card existingWork="thurid"></card>
  <card existingWork="selected-drawings"></card>
  <card existingWork="vr-sound"></card>
  <card existingWork="icc-hoehne"></card>
  <card existingWork="croquis"></card>
  <card existingWork="kreativwerkbank"></card>

</div>
