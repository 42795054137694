<div class="viewport section-margin">
  <div class="cover-photo" style="background-image: url('assets/img/fullres/vrkid/mette_boldt_vrkid_01.jpg')">
    <div class="label" style="color: white; font-size: 0.5rem; line-height: 0.5rem; position:absolute; right: 1rem; bottom: 1rem">Child model: Niklas B.</div>
  </div>
  <div class="max-width-container vertical-center">
    <info-box title="vrKid">
      <info-box-item label="Category">
        <div class="label category speculative-design">Speculative design</div>
      </info-box-item>
      <info-box-item label="Year">
        2016
      </info-box-item>
      <info-box-item label="Institution">
        University of the Arts Bremen
      </info-box-item>
      <info-box-item label="Work mode">
        Individual
      </info-box-item>
      <info-box-item label="Skills">
        Physical prototyping <span class="item-divider">//</span> Fusion 360 <span class="item-divider">//</span> 3D printing
      </info-box-item>
      <info-box-item label="Achievements">
        <a href="https://www.hfk-bremen.de/t/neuigkeiten-und-presse/n/hfk-hochschulpreise-vergeben" target="_blank">HfK Hochschulpreis #1 2017</a>  <span class="item-divider">//</span> Exhibition at Push UX 2016, Munich <span class="item-divider">//</span> <a href="https://www.flickr.com/photos/arselectronica/36946322222/in/album-72157688616394345/" target="_blank">VR Lab timeline</a> at Ars Electronica, Linz
      </info-box-item>
    </info-box>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">

    <div class="standard-margin">
      <h2>Summary</h2>
      <p class="standard-margin">vrKid is a physical prototype for a VR helmet designed especially for babies and toddlers. The helmet design protects the child from the physical surroundings, and the built-in bluetooth speakers secure safe use with no dangerous wires. 3D printing was used as a technology for creating the final model as well as for testing design solutions during the design process.</p>

      <p>The design aims to enable discussions about how far we want convenient technologies to go, and whether we reach an ethical red line when the topic is our most loved ones: Our children. The design project led to interesting discussions, from which quotes are added to this page.</p>
    </div>

    <div class="standard-margin">
      <quote
        quote="The jury were convinced by this astonishing object, its exhibit, and its demonstration. In all aspects, the interchange between irony and plausibility was perfectly explored. With every anecdote, the possibility of 'falling into the trap' was enabled."
        person='Jury, <a href="https://www.hfk-bremen.de/t/neuigkeiten-und-presse/n/hfk-hochschulpreise-vergeben/#cs_12" target="_blank">HfK Hochschulpreis 2017</a> (translated from German)'>
      </quote>
    </div>

  </div>
</div>

<div class="max-width-container-img standard-margin img-row-three">
  <img loading="eager" src="assets/img/fullres/vrkid/mette_boldt_vrkid_02.jpg">
  <img loading="eager" src="assets/img/fullres/vrkid/mette_boldt_vrkid_03.jpg">
  <img loading="eager" src="assets/img/fullres/vrkid/mette_boldt_vrkid_04.jpg">
</div>

<div class="max-width-container-img section-margin img-row-two">
  <img loading="eager" src="assets/img/fullres/vrkid/mette_boldt_vrkid_08.jpg">
  <img loading="eager" src="assets/img/fullres/vrkid/mette_boldt_vrkid_05.jpg">
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">

    <div class="section-margin">
      <quote
        quote="If you buy this, you’re the kind of parent who would rather sit and watch TV than spend time with your child."
        person="Visitor at PUSH UX 2016">
      </quote>
    </div>

  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">

    <div class="standard-margin">
      <h2>The challenge</h2>
      <p class="standard-margin">Convenient design solutions are available for everything from internet of things smart kitchen equipment to <a href="https://store.petsafe.net/bolt-cat-toy" target="_blank">interactive pet toys</a> helping our cat to have fun while we can sit around on the couch, not lifting a finger. With increasing use of technology, also for young children, the question is how far we want the convenient technologies to go. I wanted to focus on this problem in a design project, for which I formulated the following challenge:</p>

      <p class="emphasis">How might I use design as a means of enabling discussions about the future of technology for children and to increase awareness of the technologies we invite into our lives?</p>
    </div>

    <div class="standard-margin">
      <quote
        quote="This is a great idea. My son loves the iPad, so why not?"
        person="A friend">
      </quote>
    </div>

  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">

    <div class="standard-margin">
      <h2>The solution</h2>
      <p class="standard-margin">Speculative designs are great for enabling discussions because they allow us to explore possible futures before technology gets that far - or too far. My initial approach in this project was to digitize the least digitizable thing I could imagine concerning children: The emotional interaction between parent and child. This led to an idea for a VR application called “vrParents”, in which children could see their parents in a virtual environment – when the parents are away or do not have time. The app concept is described later on this page.</p>

      <p>I decided to focus on a physical prototype for the actual headset due to the immediate visual character of a physical object.</p>
    </div>

  </div>
</div>


<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>The design</h2>
    <p class="standard-margin">Plausibility was a keyword for the design of the helmet. I wanted the design to be convincing, as I believe the most interesting discussions arise when we can somewhat picture the object in question as being a part of our lives. This is why practical and emotional conditions concerning products for children were taken into account in the design process; the helmet offers protection from the surroundings, cushioning, an adjustable size, and a cuteness factor.</p>
    <p class="standard-margin">vrKid is a smartphone-based VR headset. The smartphone is inserted into the helmet's front from the bottom. The sliding piece for the smartphone can be taken out and fastened by the turning mechanism of the hippo's teeth, while a hole through the hippos nostril allows for AR applications.</p>

    <div class="section-margin">
      <quote
        quote="In 2-5 years, this will be completely normal."
        person="Visitor at PUSH UX 2016">
      </quote>
    </div>
  </div>
</div>

<div class="max-width-container-img section-margin img-row-two">
  <img loading="eager" src="assets/img/fullres/vrkid/mette_boldt_vrkid_07.jpg">
  <img loading="eager" src="assets/img/fullres/vrkid/mette_boldt_vrkid_09.jpg">
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <div class="section-margin">
      <quote
        quote="But do you really think the children can tell the difference between what they see and the real world?"
        person="Visitor at PUSH UX 2016">
      </quote>
    </div>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>Low-fi prototyping</h2>
    <p class="section-margin">Physical prototyping was a crucial part of the design process. When I got the idea that the VR headset should offer some kind of protection, I bought a used children's bike helmet. Modding it, I quickly got a feeling for size and shape. When I first attached a thick front to the original bike helmet, I realized that the object looked enourmous, which made it significantly less adorable than I had pictured it. Therefor, as the next step, I started cutting into the bike helmet to see how I could decrease the size. During the process, a low poly style emerged, which I decided to keep in the final design.</p>
  </div>
</div>

  <div class="max-width-container-img section-margin img-row-two">
    <img loading="eager" src="assets/img/fullres/vrkid/mette_boldt_vrkid_helmet_test_1.jpg">
    <img loading="eager" src="assets/img/fullres/vrkid/mette_boldt_vrkid_helmet_test_2.jpg">
  </div>


<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>Sketches</h2>
    <p>My first ideas for the design were rather simple: A more or less regular VR design, but with children-associated colors and fluffiness all over. It was when I got inspiration from bike helmets that I started seeing a shape which could look like an animal head. I continued sketching, brainstorming animals which could match the general shape; a head with a big, flat nose. In the end, I chose the hippo for two reasons.</p>

    <p>First of all, I liked that I could use traits from the hippo's look practically. Thus, as already mentioned, the nostril and the teeth are not only added purely for decoration; they have practical purposes as well.</p>

    <p>Second of all, the hippo was also a perfect methaphor for the project in general. Hippos look chubby and cute on the outside, although they are the second-most human-killing animal in Africa (right after the mosquito which I, for obvious reasons, did not consider for this project). Similarly, vrKid may look cute and harmless at first glance, though VR is not recommended for children that young due to health concerns.</p>

    <div class="section-margin">
      <quote
        quote="I just love hippos. They look so cute, but they’re actually really dangerous."
        person="Visitor at PUSH UX 2016">
      </quote>
    </div>
  </div>
</div>

  <div class="max-width-container-img standard-margin img-row-two">
    <img loading="eager" src="assets/img/fullres/vrkid/mette_boldt_vrkid_sketch_1.png">
    <img loading="eager" src="assets/img/fullres/vrkid/mette_boldt_vrkid_sketch_2.jpg">
  </div>

  <div class="max-width-container-img standard-margin img-row-one">
    <img loading="eager" src="assets/img/fullres/vrkid/mette_boldt_vrkid_sketch_3.png">
  </div>

  <div class="max-width-container section-margin">
    <div class="text-container center img-row-one">
      <img loading="eager" src="assets/img/fullres/vrkid/mette_boldt_vrkid_sketch_4.png">
    </div>
  </div>



<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>The final model</h2>
    <p>The final model was modelled in Fusion 360. To get the head shape right, the helmet was modelled around a 3D model of a baby's head.</p>

    <p class="section-margin">The model was 3D-printed in PLA on a prusa i3, sanded by hand and glued together. The printing time alone added up to over 50 hours.</p>
  </div>
</div>

  <div class="max-width-container-img section-margin img-row-two">
    <img loading="eager" src="assets/img/fullres/vrkid/mette_boldt_vrkid_model_1.jpg">
    <img loading="eager" src="assets/img/fullres/vrkid/mette_boldt_vrkid_model_2.jpg">
  </div>


<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>App concepts</h2>
    <p class="section-margin">When vrKid was first exhibited at a smaller exhibition called <a href="http://simulacrum-tmbsoh23hsmtppa23rwsamrtnsamt23hswwmyc.digitalmedia-bremen.de/" target="_blank">Simulacrum</a> at University of the Arts Bremen, only the object as well as a photo of the helmet in use were displayed. I realised the visitors had a hard time discussing the object because they could not imagine what the headset would be used for. Therefor, when the object was next exhibited at <a href="https://push-conference.com/2016/" target="_blank">PUSH UX 2016</a>, I brought a poster with three app concepts. These brought context to the design and led to interesting discussions with the visitors, some of which are quoted on this page.</p>

    <h3>vrParents</h3>
    <p>vrParents is an app which lets your child socialize while you are away. You can record your own interaction with your child or use one of the template characters to keep your child company while you are busy or away. </p>

    <p>vrParents was in fact one of the early ideas leading to the design of vrKid. It arose from the thought: "What if I digitalized the least digitizable thing I can imagine?" </p>

    <div class="standard-margin">
      <quote
        quote="I would understand using the vrParents app if, say, you have to go away for like 6 months to work at an oil platform or something."
        person="Visitor at PUSH UX 2016">
      </quote>
    </div>
    <div class="img-row-one section-margin">
      <img loading="eager" src="assets/img/fullres/vrkid/mette_boldt_vrkid_apps_vrparents.jpg">
    </div>

    <h3>VocaBuca</h3>
    <p class="standard-margin">VocaBuca helps your child learn new words by offering different scenes with oral descriptions of objects and animals. The app has voice recognition and will appraise the child when it repeats one or more of the words.</p>
    <div class="img-row-one section-margin">
      <img loading="eager" src="assets/img/fullres/vrkid/mette_boldt_vrkid_apps_vocabuca.jpg">
    </div>

    <h3>Fairyfy</h3>
    <p class="standard-margin">Fairyfy is an AR app which spices up your baby's real surroundings. Using the phone's camera, Fairyfy will invite fairytale creatures such as singing birds and unicorns into your livingroom.</p>
    <div class="img-row-one section-margin">
      <img loading="eager" src="assets/img/fullres/vrkid/mette_boldt_vrkid_apps_fairyfy.jpg">
    </div>

    <h2>Results</h2>
    <p class="standard-margin">The final design was exhibited at University of the Arts, where it won the first prize for Digital Media at the <a href="https://www.hfk-bremen.de/t/neuigkeiten-und-presse/n/hfk-hochschulpreise-vergeben/#cs_12" target="_blank">2017 Hochschulpreise</a>. It was also exhibited at the <a href="https://www.flickr.com/photos/pushconf/30597090125/in/album-72157675666457166/" target="_blank">PUSH UX conference 2016</a> and selected for the <a href="https://www.flickr.com/photos/arselectronica/36946322222/in/album-72157688616394345/" target="_blank">VR lab timeline</a> of the Ars Electronica exhibition <a href="https://ars.electronica.art/center/en/exhibitions/new-views-of-humankind/" target="_blank">New Views of Humankind</a>.</p>

    <p class="standard-margin">I had many interesting discussions based on the object, and I want to emphasize one conversation I had at the PUSH UX conference. I talked to a conference visitor, who was immediately excited about the concept. He said:</p>

    <div class="standard-margin">
      <quote
        quote="I would definitely get it for my 2-year-old daughter. My only concern is that I might have to convince her about the hippo thing. You know, she might prefer something like a dragon."
        person="Visitor at PUSH UX 2016">
      </quote>
    </div>

    <p class="standard-margin">We talked about the design for a few minutes, before I told him that VR is not recommended for kids that young because we do not know for sure which long-term consequences the technology might have on young children, both in terms of physical development of the eyes as well as social consequences. The visitor, obviously shocked that he had been so enthusiastic, said:</p>

    <div class="standard-margin">
      <quote
        quote="Oh. Now I wonder why I'm so open-minded."
        person="Visitor at PUSH UX 2016">
      </quote>
    </div>

    <p>That was when I knew my was design effective.</p>
  </div>

</div>

<related-works>
  <card existingWork="unwrap"></card>
  <card existingWork="spy"></card>
  <card existingWork="you-shall-not-pass"></card>
</related-works>
