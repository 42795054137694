import { Component } from '@angular/core';
import { WorkDetailsPageComponent } from '../../components/work-details-page/work-details-page.component';
import { WorksService } from '../../works.service';

@Component({
  selector: 'app-works-croquis',
  templateUrl: './works-croquis.component.html',
  styleUrls: ['./works-croquis.component.scss'],
})
export class WorksCroquisComponent extends WorkDetailsPageComponent {
  constructor(worksSevice: WorksService) {
    super(worksSevice);
    super.workName = 'croquis';
  }
}
