<div class="viewport section-margin">
  <div class="cover-photo" style="background-image: url('assets/img/fullres/vr-sound/boldt_etal_vr_sound_thumbnail_1.jpg')">
  </div>
  <div class="max-width-container vertical-center">
    <info-box
    title="Surround Sound's Ability To..."
    subTitle="...lead the attention of the viewer in vr">


      <info-box-item label="Category">
        <div class="label category research">Research</div>
      </info-box-item>
      <info-box-item label="Year">
        2016
      </info-box-item>
      <info-box-item label="Institution">
        University of Bremen
      </info-box-item>
      <info-box-item label="Work mode">
        Group work
      </info-box-item>
      <info-box-item label="Skills">
        Research design <span class="item-divider">//</span> Statistical Analysis
      </info-box-item>
    </info-box>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>Abstract</h2>
    <p class="standard-margin"><i>Surround Sound's Ability to Lead the Attention of the Viewer in Virtual Reality</i></p>
    <p class="standard-margin">In Virtual Reality settings, 3-D sound plays a very important role in improving realism and immersion. In this paper we explore the effect of surround sound on the direction where viewers are looking and compared it to the effect of 2-D sound. Through a self-produced piece of virtual reality film on two groups of viewers, the results not only confirm several previous findings on sound localization, but we were able to conclude that the sound does lead the attention of the viewer and has a strong effect on the location where the viewer is directing the attention.</p>

    <div class="section-margin">
      <a href="assets/pdf/boldt_et_al_vr_sound_report.pdf" target="_blank">
        <button >Read the full paper</button>
      </a>
    </div>
  </div>
</div>

<div class="max-width-container-img section-margin">
  <div class="img-row-two">
    <img loading="eager" src="assets/img/fullres/vr-sound/boldt_etal_vr_sound_thumbnail_2.jpg">
    <img loading="eager" src="assets/img/fullres/vr-sound/boldt_etal_vr_sound_thumbnail_3.jpg">
  </div>

</div>

<related-works>
  <card existingWork="you-shall-not-pass"></card>
  <card existingWork="spy"></card>
  <card existingWork="vrKid"></card>
</related-works>
