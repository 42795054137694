import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ProjectType } from 'src/types/project-type';
import { WorkInfo } from 'src/types/WorkInfo';

@Injectable({
  providedIn: 'root',
})
export class WorksService {

  currentWorkChange: Subject<WorkInfo> = new Subject<WorkInfo>();

  currentWork: WorkInfo;

  availableWorks: Map<string, WorkInfo> = new Map([
    [
      'vrKid',
      new WorkInfo(
        1,
        ProjectType.Speculative,
        'VrKid',
        'assets/img/thumbnail/mette_boldt_vrkid_thumbnail.jpg',
        '/vrkid',
        'A virtual reality helment designed especially for babies and toddlers'
      ),
    ],

    [
      'you-shall-not-pass',
      new WorkInfo(
        2,
        ProjectType.Research,
        'You shall not pass',
        'assets/img/fullres/you-shall-not-pass/boldt_et_al_you_shall_not_pass_mockup.jpg',
        '/you-shall-not-pass',
        'A research project evaluating a non-intrusive feedback design for virtual walls in VR environments with room-scale mapping'
      ),
    ],

    [
      'unwrap',
      new WorkInfo(
        3,
        ProjectType.Speculative,
        'Unwrap',
        'assets/img/thumbnail/mette_boldt_unwrap_a_thumbnail.jpg',
        '/unwrap',
        'A device for smartphones which lets others see the app currently in use'
      ),
    ],

    [
      'spy',
      new WorkInfo(
        4,
        ProjectType.GameDesign,
        'S.P.Y',
        'assets/img/fullres/spy/boldt_etal_spy_1.jpg',
        '/spy',
        'A prototype for a sci-fi VR exergame which helps treating and preventing back pain'
      ),
    ],

    [
      'upgrade-event',
      new WorkInfo(
        5,
        ProjectType.WebDesign,
        'Upgrade Event',
        'assets/img/fullres/upgrade-event/mette_boldt_upgrade_event_mockup.jpg',
        '/upgrade-event',
        'A commissioned website for a services and event agency'
      ),
    ],

    [
      'in-your-face-design-for-public-transport',
      new WorkInfo(
        6,
        ProjectType.Teaching,
        'In your face',
        'assets/img/fullres/in-your-face/alexandre-debieve-585744-unsplash.jpg',
        '/in-your-face-design-for-public-transport',
        'A semester course I taught at University of the Arts Bremen on design for public transport as a social space'
      ),
    ],

    [
      'thurid',
      new WorkInfo(
        7,
        ProjectType.GameDesign,
        'Thurid',
        'assets/img/fullres/thurid/boldt_bonfert_taake_thurid_intro.jpg',
        '/thurid',
        'A short game prototype for an action-adventure fantasy game set in the world of the vikings'
      ),
    ],

    [
      'selected-drawings',
      new WorkInfo(
        8,
        ProjectType.Drawing,
        'Drawings and Illu&shy;stra&shy;tions',
        'assets/img/thumbnail/mette_boldt_old_man_thumbnail.jpg',
        '/drawings-and-illustrations',
        'A selection of drawings and illustrations with different styles and materials from recent years'
      ),
    ],

    [
      'vr-sound',
      new WorkInfo(
        9,
        ProjectType.Research,
        'Surround Sound\'s Ability to...',
        'assets/img/fullres/vr-sound/boldt_etal_vr_sound_thumbnail_1.jpg',
        '/surround-sounds-ability-to-lead-the-attention-of-the-viewer-in-vr',
        'A between-groups research project exploring the effect of surround sound on the attention of viewers in VR'
      ),
    ],

    [
      'icc-hoehne',
      new WorkInfo(
        10,
        ProjectType.WebDesign,
        'ICC Höhne',
        'assets/img/fullres/icc-hoehne/mette_boldt_icc_hoehne_mockup.jpg',
        '/icc-hoehne',
        'A commissioned website for an automotive supplier'
      ),
    ],

    [
      'croquis',
      new WorkInfo(
        11,
        ProjectType.Drawing,
        'Croquis',
        'assets/img/thumbnail/mette_boldt_croquis_thumbnail.jpg',
        '/croquis',
        'A collection of croquis drawings'
      ),
    ],

    [
      'kreativwerkbank',
      new WorkInfo(
        12,
        ProjectType.WebDesign,
        'Kreativ&shy;Werkbank',
        'assets/img/fullres/kreativwerkbank/mette_boldt_kreativwerkbank_mockup.jpg',
        '/kreativwerkbank',
        'A commissioned website for a local advertising agency'
      ),
    ],




  ]);

  constructor() {
    this.currentWorkChange.subscribe((work: WorkInfo) => {
      this.currentWork = work;
  });
  }

  public setCurrentWork(name: string): void {
    if (name) {
      this.currentWorkChange.next(this.availableWorks.get(name));
    } else {
      this.currentWorkChange.next(undefined);
    }
  }

  public fetchAllWorks(): Map<string, WorkInfo> {
    return this.availableWorks;
  }

  public getSpecificWorkByName(name: string): WorkInfo {
    return this.availableWorks.get(name);
  }

  public getSpecificWorkByIndex(index: number): WorkInfo {
    const values: WorkInfo[] = Array.from(this.availableWorks.values());
    return values.filter((work) => work.index === index)[0];
  }

  public getPreviousWorkLink(): string {
    let prevIndex = this.currentWork.index - 1;
    if (prevIndex < 1) {
      prevIndex = this.availableWorks.size;
    }
    const previousWork = this.getSpecificWorkByIndex(prevIndex);
    return previousWork.routerLink;
  }

  public getNextWorkLink(): string {
    let nextIndex = this.currentWork.index + 1;
    if (nextIndex > this.availableWorks.size) {
      nextIndex = 1;
    }
    const nextWork = this.getSpecificWorkByIndex(nextIndex);
    return nextWork.routerLink;
  }
}
