import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'related-works',
  templateUrl: './related-works.component.html',
  styleUrls: ['./related-works.component.scss']
})
export class RelatedWorksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
