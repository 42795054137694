<div class="card">
  <img loading="eager" src="{{ imageLink }}" />
  <span class="label category {{ projectTypeClass }}">{{ projectTypeText }}</span>
  <div class="card-text-container">
    <h3 [innerHTML]="title"></h3>
    <p>
      <ng-template [ngIf]="existingWork">{{ description }}</ng-template>
      <ng-template [ngIf]="!existingWork"
        ><ng-content></ng-content
      ></ng-template>
    </p>
  </div>
  <a routerLink="{{ routerLink }}"></a>
</div>

