<div class="viewport section-margin">
  <div class="cover-photo" style="background-image: url('assets/img/fullres/in-your-face/alexandre-debieve-585744-unsplash.jpg')">
  </div>
  <div class="label" style="color: white; font-size: 0.5rem; line-height: 0.5rem; position:absolute; right: 1rem; bottom: 1rem">Just a photo from Unsplash to set the mood</div>
  <div class="max-width-container vertical-center">
    <info-box
    title="In your face"
    subTitle="design for the use of public transport">
      <info-box-item label="Category">
        <div class="label category teaching">Teaching</div>
      </info-box-item>
      <info-box-item label="Year">
        2019
      </info-box-item>
      <info-box-item label="Institution">
        University of the Arts Bremen
      </info-box-item>
      <info-box-item label="Skills">
        Teaching <span class="item-divider">//</span> Presenting <span class="item-divider">//</span> Microsociology <span class="item-divider">//</span> Speculative design <span class="item-divider">//</span> Observation & interviewing
      </info-box-item>
    </info-box>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>Introduction</h2>
    <p class="standard-margin">When my former professor at University of the Arts Bremen asked me whether I would be interested in teaching my own semester course for the master students of the Digital Media programme, the decision was easy: Yes.</p>

    <p class="standard-margin">The course structure at University of the Arts is dynamic. The courses are split into different modules, such as "design" and "informatics", but the specific focus areas of the individual courses are open and vary every semester. This meant that I could, within the world of "design" and "digital media", define my own class.</p>

    <p class="standard-margin">I decided I wanted the course to focus on face to face interactions in public transport. The main reason for this is that I simply find public transport an extremely interesting space in terms of social interactions, because people of different backgrounds meet and are more or less forced to spend a certain amount of time there. For someone like me, who holds an interest in microsociology, this has a huge design potential.</p>

    <p class="standard-margin">Furthermore, I had had good experiences evaluating design in public transport. For my design project <a routerLink='/unwrap'>Unwrap</a>, I held test sessions in different public locations. I realised that observing and interviewing strangers worked exceptionally well in public transport for one simple reason: People stay in the same place for some time. Therefor, one has more time to observe their reactions, and they are likely to have time for a spontaneous interview seeing that they are typically just waiting to arrive.</p>

    <div class="standard-margin">
      <quote
        quote="This course gave everyone the chance to develop and work on an idea, they really liked and wanted to do. [...] I didn't feel pressured into a special way of doing things which gave me the liberty to really concentrate on my vision."
        person="Student feedback from anonymous survey">
      </quote>
    </div>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>The semester plan</h2>
    <p class="standard-margin">I structured the course with a mixture of theoretical presentations, practical exercises and individual work. Below are listed the main events of the semester plan. In between, the students worked on their respective designs individually, and I held frequent individual feedback sessions to make sure every student got the support they needed.</p>

    <div class="timeline section-margin">
      <div class="element">
          <span class="label">Introduction and Brainstorming</span><br>
          <p>Gathering initial inspiration from mutual brainstorming and research on related design work</p>
      </div>
      <div class="element">
        <span class="label">First Assignment</span><br>
        <p>Exploring the social space of public transport</p>
      </div>
      <div class="element">
        <span class="label">Presentation: Microsociology</span><br>
        <p>The first theoretical presentation, giving a theoretical foundation in how people interact in face to face situations</p>
      </div>
      <div class="element">
        <span class="label">Second assignment</span><br>
        <p>An early beginning of documenting the design process and the ideas.</p>
      </div>
      <div class="element">
        <span class="label">Presentation: Speculative design</span><br>
        <p>A presentation on speculative design as inspiration</p>
      </div>
      <div class="element">
        <span class="label">Presentation: Observation and interviewing</span><br>
        <p>Presentation and field work as a preparation for the students' evaluation</p>
      </div>
      <div class="element">
        <span class="label">Intermediate presentations</span><br>
        <p>Presenting the design projects in a intermediate state before the semester break</p>
      </div>
      <div class="element">
        <span class="label">Final documentation</span><br>
        <p>The final hand-in including design documentation and evaluation</p>
      </div>
    </div>

    <quote
      quote="It is important to adhere to the topic of the course. But you didn't push us to ideas, but rather carefully helped us to create something relevant to the topic."
      person="Student feedback from anonymous survey">
    </quote>
  </div>
</div>


<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2 class="small-margin">Introduction and</h2>
    <h2>Brainstorming</h2>
    <p class="standard-margin">In the first class, I introduced the overall topic as well as some related design projects for inspiration. We held a mutual brainstorming session on interesting situations in public transport, which could inspire ideas.</p>

    <p class="standard-margin">It was important for me that the students could follow each other's design processes and gather inspiration form each other. Therefor, all research students were encouraged to share the interesting things they found during research and brainstorming in a Slack channel for the course.</p>

    <a href="assets/pdf/in-your-face/20190429_Mette_Boldt_In_Your_Face_Introduction_Slides.pdf" target="_blank">
      <button class="standard-margin">Introduction slides</button>
    </a>

    <quote
      quote="From the beginning there were clear goals and milestones presented. I always knew what to do next and when to do it."
      person="Student feedback from anonymous survey">
    </quote>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>First assignment</h2>
    <p class="standard-margin">I believe that in order to do good designs, one has to "get out there". Therefor, one of the first things I did was sending the students out to use public transport. In Bremen, all students enrolled at university have a public transport ticket for the semester, so it is a common means of transportation. With this exercise, I wanted the students to explicitly pay attention to their surroundings and enter the space with curiosity, not just a need to get from A to B.</p>

    <p class="standard-margin">The assignment was split into three parts:</p>

    <ol class="standard-margin" style="margin-top: 0">
      <li class="small-margin">Do research and brainstorm as many ideas as possible - good and bad.</li>
      <li class="small-margin">Enter the space of public transport, observe, and challenge it by doing something you normally would not, such as starting a conversation with a stranger.</li>
      <li>Return to brainstorming and select 3 ideas to present to the class.</li>
    </ol>

    <p class="standard-margin">The students told many funny and interesting stories about their experiences with the first assignment. It was particularly interesting for me to see how their experiences with the second part of the assignment shaped their later design ideas. Some examples:</p>

    <p class="standard-margin">One student started a conversation with a stranger on a long-distance train, which he could not get out of again. He ended up making a design for route planning, which could avoid trains with many passengers. </p>

    <p class="standard-margin">One student felt uncomfortable behaving differently in the space. She ended up creating a camouflage train dress to blend into the surroundings.</p>

    <p class="standard-margin">And one student brought a jack splitter cable for headphones and asked strangers to listen in on their music. He ended up creating an app where strangers on the same train can stream each others' music.</p>

    <quote
      quote="The first assignment was a pretty fun exercise that helped assess the general mood in public transport and produced many fine ideas."
      person="Student feedback from anonymous survey">
    </quote>
  </div>
</div>


<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2 class="small-margin">Presentation:</h2>
    <h2>Microsociology</h2>
    <p class="standard-margin">I have always been interested in people and how we interact with each other. Therefor, it is no wonder that the theories I learned in sociology during my bachelor studies stuck with me. Since I find public transport so interesting from a social interaction perspective, I decided to teach the students some basic sociology to give them a foundation and inspiration.</p>

    <a href="assets/pdf/in-your-face/20190506_Mette_Boldt_In_Your_Face_Microsociology_Slides.pdf" target="_blank">
      <button class="standard-margin">Microsociology slides</button>
    </a>

    <quote
      quote="Your enthusiasm was really refreshing, always like to listen to someone with your presentation skills."
      person="Student feedback from anonymous survey">
    </quote>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>Second assignment</h2>
    <p class="standard-margin">By the time the second assignment was handed out, the students had already decided on their final design ideas. The second assignment aimed to help the students collect their thoughts and get an early start with the documentation of their designs.</p>

    <p class="standard-margin">In the second assignment, the students were asked to answer simple questions about their designs:</p>

    <ol class="standard-margin" style="margin-top: 0">
      <li class="small-margin"><strong>What</strong> do you want to design?</li>
      <li class="small-margin"><strong>How</strong> do you want to create it?</li>
      <li class="small-margin"><strong>Whom</strong> does it concern?</li>
      <li class="small-margin"><strong>Where</strong> do you want to evaluate it?</li>
      <li><strong>Why</strong> is it interesting?</li>
    </ol>

    <quote
      quote="Some questions helped me more (whom, why), others a bit less (what, how), however overall I would do it the same way again, since it was quite beneficial to have parts where I have to really think about the question and others where I could just write and reinforce my thoughts."
      person="Student feedback from anonymous survey">
    </quote>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2 class="small-margin">Presentation:</h2>
    <h2>Speculative design</h2>
    <p class="standard-margin">A presentation on speculative design was not originally planned for the course. However, as we had some more space in the middle of the process and the students were interested in the topic, which had naturally come up during the research phase, I added an extra presentation session on the topic.</p>

    <a href="assets/pdf/in-your-face/20190520_Mette_Boldt_In_Your_Face_Speculative_Design_Slides.pdf" target="_blank">
      <button class="standard-margin">Speculative design slides</button>
    </a>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2 class="small-margin">Presentation:</h2>
    <h2 class="small-margin">Observing and</h2>
    <h2>interviewing</h2>
    <p class="standard-margin">I do not think any design is done, until it has been evaluated somehow in the real world. Therefor, one of the course requirements was that the students had to test and evaluate their designs. To prepare them for that, I held a presentation session on observation and interviewing techniques, which they could apply if they found that these would be fitting evaluation methods for their particular projects.</p>

    <p class="standard-margin">All theoretical presentations I held during the semester were followed by practical exercises. The practical exercise part of this topic was different in that it took place outside the classroom.</p>

    <a href="assets/pdf/in-your-face/20190617_Mette_Boldt_In_Your_Face_Interviewing_and_Observation_Slides.pdf" target="_blank">
      <button class="standard-margin">Observing and interviewing slides</button>
    </a>

    <div class="standard-margin">
        <quote
        quote="The topics of the theoretical sessions, which are important in any case, were not quite in my field of interest, but your enthusiasm did help a lot to not switch off that much."
        person="Student feedback from anonymous survey">
      </quote>
    </div>

    <quote
      quote="The exercises after the theory sessions were also fun, maybe they could be integrated more in between."
      person="Student feedback from anonymous survey">
    </quote>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2 class="small-margin">Intermediate</h2>
    <h2>presentations</h2>

    <p class="standard-margin">In the last meeting before the semester break, the students were asked to do an intermediate presentation showing the current state of their work. This was also the last chance to get personal feedback from me before the semester break, during which they would finish their documentations.</p>

    <quote
      quote="[The feedback was] Super helpful, got a lot of new insights, many suggestions of what I should consider and ways to improve, which I really love."
      person="Student feedback from anonymous survey">
    </quote>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>Final documentation</h2>
    <p class="standard-margin">The form of the final documentation was left open, though some form of evaluation of the designs was mandatory.</p>

    <p class="standard-margin">The students' final designs were all interesting, and very different. Some made designs that facilitate more privacy, while one made an app for the exact opposite purpose, namely to open up for conversations between strangers. The different ideas have shown me that mobility is indeed an interesting space to explore from a design perspective.</p>

    <p class="standard-margin">It is also a space which one can keep developing as it changes with time. One student in the course designed a hygenic handle as an alternative to the poles we all hold on to and thereby touch in the train to prevent losing balance. In the summer 2019, this was meant as an object to open up for discussions about hygiene in public transport. None of us had anticipated that not even a year later, with the Corona pandemic, hygiene in public transport would become more relevant than ever in a much more serious way.</p>

    <quote
      quote="Even though I didn't need too much feedback, I always felt like I could get help from you. It was a very nice offer to give feedback on a first draft of the documentation."
      person="Student feedback from anonymous survey">
    </quote>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>Acknowledgements</h2>
    <p class="standard-margin">Teaching this course has been one of the most rewarding experiences I have had as a designer. I want to thank my former professor, Peter von Maydell, for giving me this opportunity and for supporting me whenever I had question. I also thank my employer Capgemini for the flexible working hours and part-time policy which allowed me to teach the course one day a week while still working my regular job.</p>

    <p class="standard-margin">And of course, I want to thank the students for their commitment and great design ideas. After the course was over and all grades were given, I sent out an anonymous survey to collect their feedback for my first teaching experience. The feedback was overwhelmingly positive, and I added some of the quotes on this page. Thank you!</p>

    <quote
      quote="I really enjoyed the course, the topic and the atmosphere. Best course I've taken this semester!"
      person="Student feedback from anonymous survey">
    </quote>
  </div>
</div>

<related-works>
  <card existingWork="unwrap"></card>
</related-works>
