<div class="viewport section-margin">
  <div
    class="cover-photo"
    style="
      background-image: url('assets/img/fullres/thurid/boldt_bonfert_taake_thurid_intro.jpg');
    "
  ></div>
  <div class="max-width-container vertical-center">
    <info-box title="Thurid">
      <info-box-item label="Category">
        <div class="label category game-design">Game design</div>
      </info-box-item>
      <info-box-item label="Year"> 2016 </info-box-item>
      <info-box-item label="Institution">
        University of the Arts Bremen & University of Bremen
      </info-box-item>
      <info-box-item label="Work mode"> Group work </info-box-item>
      <info-box-item label="Skills">
        Game design <span class="item-divider">//</span> Unity <span class="item-divider">//</span> Illustration <span class="item-divider">//</span> 2D animation <span class="item-divider">//</span> Sound design <span class="item-divider">//</span>
        Premiere Pro
      </info-box-item>
    </info-box>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>Introduction</h2>
    <p class="standard-margin">
      Thurid is a short game prototype for a 2D action-adventure fantasy game
      created with my friends and fellow students, Nora Taake and Michael
      Bonfert. The game is set in the world of the Vikings and the Norse
      mythology and draws on stories and visuals from that time.
    </p>

    <div class="img-row-one section-margin">
      <iframe
        src="https://player.vimeo.com/video/433930435"
        width="640"
        height="404"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
        style="width: 100%"
      ></iframe>
    </div>

    <h2>Plot</h2>
    <p class="standard-margin">
      The viking woman Thurid, the main character, has been captured by the
      Jotuns without knowing why. She has to fight her way from Jotunheim back
      to Midgard, the world of the humans, and later even further to Asgard, the
      world of the Gods, as secrets about her own past are being revealed. Along
      the way, she gets company by the ferret around her neck, who has started
      talking to her. Is she getting mad?
    </p>
  </div>

  <div class="img-row-one section-margin">
    <img
      src="assets/img/fullres/thurid/boldt_bonfert_taake_thurid_screenshots.jpg"
    />
  </div>

  <div class="text-container center section-margin">
    <h2>Contributions</h2>
    <p>
      The game is a result of great teamwork, in which I especially contributed
      in creating the concept and story of the game as well as designing visuals
      and doing frame animation. I also played a central part in sound design.
    </p>
  </div>
</div>

<div class="max-width-container-img section-margin">
  <div class="img-row-three standard-margin">
    <img
      loading="eager"
      src="assets/img/fullres/thurid/gifs/boldt_bonfert_taake_thurid_pelk_attack.gif"
    />
    <img
      loading="eager"
      src="assets/img/fullres/thurid/gifs/boldt_bonfert_taake_thurid_pelk_die.gif"
    />
    <img
      loading="eager"
      src="assets/img/fullres/thurid/gifs/boldt_bonfert_taake_thurid_bear.gif"
    />
  </div>

  <div class="img-row-two standard-margin">
    <img
      loading="eager"
      src="assets/img/fullres/thurid/boldt_bonfert_taake_thurid_yggdrasil.jpg"
    />
    <img
      loading="eager"
      src="assets/img/fullres/thurid/boldt_bonfert_taake_thurid_birch_trees.jpg"
    />
  </div>

  <div class="text-container center img-row-one standard-margin">
    <img
      loading="eager"
      src="assets/img/fullres/thurid/boldt_bonfert_taake_thurid_dead_man.jpg"
    />
  </div>
</div>

<related-works>
  <card existingWork="spy"></card>
  <card existingWork="selected-drawings"></card>
</related-works>
