import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navi',
  templateUrl: './navi.component.html',
  styleUrls: ['./navi.component.scss'],
})
export class NaviComponent implements OnInit {
  isActive: boolean;
  isOpen: boolean;

  constructor() {
    this.isActive = false;
    this.isOpen = false;
  }

  ngOnInit(): void {}

  toggleActive(): void {
    this.isActive = !this.isActive;
    this.isOpen = !this.isOpen;
  }
}
