import { Component } from '@angular/core';
import { WorkDetailsPageComponent } from '../components/work-details-page/work-details-page.component';
import { WorksService } from '../works.service';

@Component({
  selector: 'app-page-contact',
  templateUrl: './page-contact.component.html',
  styleUrls: ['./page-contact.component.scss'],
})
export class PageContactComponent extends WorkDetailsPageComponent {
  constructor(worksSevice: WorksService) {
    super(worksSevice);
    super.workName = undefined;
  }
}
