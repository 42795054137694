<div class="viewport section-margin">
  <div class="cover-photo" style="background-image: url('assets/img/fullres/you-shall-not-pass/boldt_et_al_you_shall_not_pass_mockup.jpg')">
  </div>
  <div class="max-width-container vertical-center">
    <info-box
    title="You shall not pass"
    subTitle="Non-Intrusive Feedback for Virtual Walls in VR Environments with Room-Scale Mapping">
      <info-box-item label="Category">
        <div class="label category research">Research</div>
      </info-box-item>
      <info-box-item label="Year">
        2017-2018
      </info-box-item>
      <info-box-item label="Institution">
        University of Bremen
      </info-box-item>
      <info-box-item label="Work mode">
        Group work
      </info-box-item>
      <info-box-item label="Skills">
        Research design <span class="item-divider">//</span> Game design <span class="item-divider">//</span> Unity
      </info-box-item>
      <info-box-item label="Achievements">
        Published conference paper at <a href="https://ieeexplore.ieee.org/document/8446177" target="_blank">IEEE VR 2018</a>
      </info-box-item>
    </info-box>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>Abstract</h2>
    <p class="standard-margin"><i>You Shall Not Pass: Non-Intrusive Feedback for Virtual Walls in VR Environments with Room-Scale Mapping</i></p>
    <p class="standard-margin">Room-scale mapping facilitates natural locomotion in virtual reality (VR), but it creates a problem when encountering virtual walls. In traditional video games, player avatars can simply be prevented from moving through walls. This is not possible in VR with room-scale mapping due to the lack of physical boundaries. Game design is either limited by avoiding walls, or the players might ignore them, which endangers the immersion and the overall game experience. To prevent players from walking through walls, we propose a combination of auditory, visual, and vibrotactile feedback for wall collisions. This solution can be implemented with standard game engine features, does not require any additional hardware or sensors, and is independent of game concept and narrative. A between-group study with 46 participants showed that a large majority of players without the feedback did pass through virtual walls, while 87% of the participants with the feedback refrained from walking through walls. The study found no notable differences in game experience.</p>

    <div class="section-margin">
      <a href="assets/pdf/Boldt_etal_You-Shall-Not-Pass_IEEE-VR_authors_copy.pdf" target="_blank">
        <button >Read the full paper</button>
      </a>
    </div>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>Preview video</h2>
    <p class="standard-margin">Get a glimpse of the study design and results in the video below.</p>
    <iframe width="672" height="378" src="https://www.youtube.com/embed/ThAqHmAouJY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="width: 100%;"></iframe>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>Feedback design</h2>
    <p class="standard-margin">The feedback design evaluated in this study consists of a combination of visual, auditory and tactile feedback for hand-wall and head-wall collisions. The feedback design aims to create as realistic a sensation as possible for the player. Hand-wall collision therefor includes tactile feedback and a knocking sound simulating the sound of touching a real object. The head-wall collision feedback immitates the sensation of having a head inside a closed space, where one cannot see or hear. Therefor, the vision is black and the background music has a muffled effect similar to hearing music through a wall.</p>

    <p class="standard-margin">Below is an overview of the feedback design.</p>
    <table class="section-margin">

      <tr class="border-bottom">
        <th class="border-right"> </th>
        <th>Visual feedback</th>
        <th>Auditory feedback</th>
        <th>Tactile feedback</th>
      </tr>

      <tr>
        <th class="border-right">Head-wall collision</th>
        <td>Black vision</td>
        <td>Muffled background music</td>
        <td class="n-a">(N/A)</td>
      </tr>

      <tr>
        <th class="border-right">Hand-wall collision</th>
        <td class="n-a">(N/A)</td>
        <td>Knocking sound</td>
        <td>Vibration</td>
      </tr>

    </table>

    <quote
    quote="I touched [the wall] at some point with my hand and then it vibrated and then I thought ‘okay, this is the feedback from the game now, that this is not working’"
    person="Test participant, experimental group"
  >
    </quote>

  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>Study design</h2>
    <p class="standard-margin">The study compared test results from two groups: The <strong>experimental group</strong>, who played through the test setup with our feedback, and the <strong>control group</strong> who played through the same test setup without any feedback. Of course, none of the participants knew the purpose of the study.</p>

    <p class="standard-margin">The test setup consisted of four rooms, where the participants were gradually given more incitements to pass walls for each room. In the fourth room, crossing walls was mandatory. The users were informed about this in a break between rooms 3 and 4. The fourth room made sure that every participant had experienced walking through walls before the interview sessions.</p>

    <p class="standard-margin">The test participants were asked to complete a questionnaire before the test, which included questions about their experience with VR and playing video games so we could consider the possible effect of these on the results. After the test rooms, another questionnaire on game experience related to the test setup was completed and interviews were conducted.</p>

  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>Room design</h2>
    <p class="standard-margin">In all four rooms, the participants had to move from point <strong>A</strong> to the teleporter on point <strong>B</strong>. In rooms 1, 2 and 4, numbered red buttons had to be pushed in the correct order for the teleporter to be activated. In room 3, the teleporter was activated automatically.</p>
  </div>
</div>

<div class="max-width-container-img section-margin">
  <div class="img-row-four standard-margin">
    <div class="img-with-text">
      <img loading="eager" src="assets/img/fullres/you-shall-not-pass/boldt_et_al_you_shall_not_pass_room1.png">
      <p><strong>Room 1</strong> <br>
        Room 1 served to make the participants familiar with the environment and the functionality of pushing buttons in the correct order to activate the teleporter.
      </p>
    </div>
    <div class="img-with-text">
      <img loading="eager" src="assets/img/fullres/you-shall-not-pass/boldt_et_al_you_shall_not_pass_room2.png">
      <p><strong>Room 2</strong> <br>
        Room 2 provided incitement to pass the wall in the middle through the repetitive task of walking around it many times, having to press 8 buttons. A little window on the wall emphasized the possible shortcut through the wall.
      </p>
    </div>
    <div class="img-with-text">
      <img loading="eager" src="assets/img/fullres/you-shall-not-pass/boldt_et_al_you_shall_not_pass_room3.png">
      <p><strong>Room 3</strong> <br>
        Room 3 had no buttons. Instead, sliding doors (shaded) would close when the participants moved towards the teleporter and open when they moved away again. After 2 minutes, visualised by a timer, the sliding doors would open. The only way to get to the teleporter before the 2 minutes were up was by walking through one of the sliding walls.
      </p>
    </div>
    <div class="img-with-text">
      <img loading="eager" src="assets/img/fullres/you-shall-not-pass/boldt_et_al_you_shall_not_pass_room4.png">
      <p><strong>Room 4</strong> <br>
        For room 4, participants were explicitly instructed to walk through walls. Due to the button positions, walls had to be crossed at least 5 times before the participants could reach the teleporter.
      </p>
    </div>



  </div>
</div>


<div class="max-width-container-img standard-margin">
  <div class="img-row-three">
    <img loading="eager" src="assets/img/fullres/you-shall-not-pass/boldt_et_al_you_shall_not_pass_buttons.jpg">
    <img loading="eager" src="assets/img/fullres/you-shall-not-pass/boldt_et_al_you_shall_not_pass_teleporter_wall_1.jpg">
    <img loading="eager" src="assets/img/fullres/you-shall-not-pass/boldt_et_al_you_shall_not_pass_teleporter_wall_2.jpg">
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <quote
      quote="I tried once to walk into the wall and when it turned black I thought ‘oh, I guess that doesn’t work’ and then I didn’t do it."
      person="Test participant, experimental group"
      >
    </quote>
  </div>
</div>

<div class="max-width-container section-margin">
  <div class="text-container center">
    <h2>Results</h2>
    <p class="standard-margin">The study showed clear results: The test participants who received wall collision feedback were significantly less likely to walk through walls than the test participants who did not receive any feedback.</p>

    <p class="section-margin">In room 2, 9 of the 23 participants in the control group walked through walls. None in the experimental group did.</p>

    <div class="img-row-one standard-margin">
      <img loading="eager" src="assets/img/fullres/you-shall-not-pass/boldt_et_al_you_shall_not_pass_room_2_experimental_group_example.png">
    </div>
    <p class="section-margin">The image above is an example visualisation of the walking path of one participant in the <strong>experimental group</strong> from start (turquoise) to end (pink) in room 2. The black dots illustrate hand and head collisions with walls. The visualisation shows how the participant approached the wall relatively early on and touched it several times, yet did not cross.</p>

    <div class="img-row-one standard-margin">
      <img loading="eager" src="assets/img/fullres/you-shall-not-pass/boldt_et_al_you_shall_not_pass_room_2_control_group_example.png">
    </div>
    <p class="section-margin">This participant in the <strong>control group</strong> walked around the wall only once and then checked the non-activated teleporter. After that, he or she consequently walked through the wall between the button groups to finish quickly.</p>

    <p class="section-margin">In room 3, 19 of 23 participants in the control group walked through walls, while only three out of 23 in the experimental group did. The participants in the experimental group also waited longer on average before attempting to pass through the walls.</p>

    <div class="img-row-one standard-margin">
      <img loading="eager" src="assets/img/fullres/you-shall-not-pass/boldt_et_al_you_shall_not_pass_room3_heat_maps.png">
    </div>
    <p class="standard-margin">The images above are aggregated heat maps illustrating the head and wall collisions with walls, floor and ceiling in room 3. Although by far the most participants in the control group walked through the walls, the participants in the experimental group had more collisions. In other words: Even though they tried hard to find ways to get to the teleporter by interacting with the walls, the participants with our feedback combination were much less likely to pass the walls.</p>

    <p>Please <a href="assets/pdf/Boldt_etal_You-Shall-Not-Pass_IEEE-VR_authors_copy.pdf" target="_blank">read the full paper</a> for detailed results.</p>


  </div>
</div>

<div class="max-width-container">
  <div class="text-container center section-margin">
    <h2>Contributions</h2>
    <p class="standard-margin">This project is the result of great teamwork with my fellow students at University of Bremen. My main contributions included study design, room design, analysis of the study findings and writing the final paper. The experiment was conducted following the development of the motion-based VR sci-fi game <a routerLink="/spy">S. P. Y</a>.</p>

    <p>The research project was published and presented as conference paper at <a href="https://ieeexplore.ieee.org/document/8446177" target="_blank">IEEE VR 2018</a>. It was recently quoted in the publication <a href="https://dl.acm.org/doi/abs/10.1145/3313831.3376562" target="_blank">Do You Feel Like Passing Through Walls?: Effect of Self-Avatar Appearance on Facilitating Realistic Behavior in Virtual Environments</a> by Ogawa et al., Proceedings of the 2020 CHI Conference on Human Factors in Computing systems.</p>
  </div>

</div>

<related-works>
  <card existingWork="spy"></card>
  <card existingWork="vr-sound"></card>
  <card existingWork="vrKid"></card>
</related-works>
