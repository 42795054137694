import { Component } from '@angular/core';
import { WorkDetailsPageComponent } from '../../components/work-details-page/work-details-page.component';
import { WorksService } from '../../works.service';

@Component({
  selector: 'app-works-vr-sound',
  templateUrl: './works-vr-sound.component.html',
  styleUrls: ['./works-vr-sound.component.scss']
})
export class WorksVrSoundComponent extends WorkDetailsPageComponent {

  constructor(worksSevice: WorksService) {
    super(worksSevice);
    super.workName = 'vr-sound';
  }

}
