import { Component } from '@angular/core';
import { WorkDetailsPageComponent } from '../../components/work-details-page/work-details-page.component';
import { WorksService } from '../../works.service';

@Component({
  selector: 'app-works-vrkid',
  templateUrl: './works-vrkid.component.html',
  styleUrls: ['./works-vrkid.component.scss'],
})
export class WorksVrkidComponent extends WorkDetailsPageComponent {
  constructor(worksSevice: WorksService) {
    super(worksSevice);
    super.workName = 'vrKid';
  }
}
