import { Component } from '@angular/core';
import { WorkDetailsPageComponent } from '../../components/work-details-page/work-details-page.component';
import { WorksService } from '../../works.service';

@Component({
  selector: 'app-works-you-shall-not-pass',
  templateUrl: './works-you-shall-not-pass.component.html',
  styleUrls: ['./works-you-shall-not-pass.component.scss'],
})
export class WorksYouShallNotPassComponent extends WorkDetailsPageComponent {
  worksService: WorksService;

  constructor(worksSevice: WorksService) {
    super(worksSevice);
    super.workName = 'you-shall-not-pass';
  }
}
