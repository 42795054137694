<div (click)="toggleActive()" [ngClass]="{'open': isOpen}" class="nav">
  <div class="max-width-container">
    <div [ngClass]="{'active': isActive}" class="nav-trigger">
      <i></i><i></i><i></i>
    </div>
    <div class="home">
      <a routerLink='index' routerLinkActive='active'>Mette Boldt</a>
    </div>
    <div>
      <a routerLink='works' routerLinkActive='active'>Work</a>
      <a routerLink='about' routerLinkActive='active'>About</a>
      <a routerLink='contact' routerLinkActive='active'>Contact</a>
    </div>
  </div>
</div>

